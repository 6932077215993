import React from 'react'
import mediaQueries from '../../styles/media-queries'

interface Props {
  className?: string
}

const PresentationSection: React.FC<Props> = ({ children, className }) => {
  return (
    <div
      css={{
        display: 'flex',
        marginTop: 40,
        flexDirection: 'column',
        [mediaQueries('md')]: {
          marginTop: 60,
        },
        [mediaQueries('lg')]: {
          marginTop: 80,
        },
      }}
      className={className}
    >
      {children}
    </div>
  )
}

export default PresentationSection
