import React from 'react'
import { css } from '@emotion/react'
import PresentationSection from '../presentation-section'
import PresentationSectionParagraph from '../presentation-section-paragraph'
import emailImg from '../../../images/Icona-lettera.png'
import callImg from '../../../images/Icona-cuffie.png'
import StepImage from './step-image'
import ProgramDescription from './program-description'
import { Psp } from '../../../types'

interface Props {
  psp: Psp
}

const StepsDescription: React.FC<Props> = ({ psp }) => {
  return (
    <React.Fragment>
      <ProgramDescription program={psp.shortName} />
      <PresentationSection>
        <StepImage src={callImg} alt="Compilazione Form" />
        <PresentationSectionParagraph>
          Per qualsiasi ulteriore informazione o necessità di chiarimento non esiti a contattare il numero verde
          dedicato{' '}
          <b
            css={css`
              font-weight: 600 !important;
              color: #2d8b10;
            `}
          >
            {psp.tollFreeNumber}
          </b>{' '}
          attivo dal Lunedì al Venerdì dalle ore 09:00 alle ore 17:00, o a scriverci all'indirizzo{' '}
          <a href={`mailto:${psp.email}`}>{psp.email}</a>
        </PresentationSectionParagraph>
      </PresentationSection>
      <PresentationSection>
        <StepImage src={emailImg} alt="Compilazione Form" />
        <PresentationSectionParagraph>
          Al momento della sottoscrizione del form sotto riportato, <b>riceverà una mail di conferma</b> con il testo
          dell’Informativa Privacy, i contatti a cui rivolgersi per l'attivazione dei servizi e le schede con la
          descrizione dettagliata di tutti i servizi.
        </PresentationSectionParagraph>
      </PresentationSection>
    </React.Fragment>
  )
}

export default StepsDescription
