import React from 'react'
import { css, Global } from '@emotion/react'
import emotionReset from 'emotion-reset'
import Routing from './routing'

function App() {
  return (
    <div>
      <Global
        styles={css`
          ${emotionReset}

          @import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

          body,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          div,
          p,
          span {
            font-family: 'Lato', sans-serif !important;
          }
        `}
      />
      <Routing />
    </div>
  )
}

export default App
