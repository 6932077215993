import { CheckboxProps } from '../subscription-page/subscription-form/checkbox'
import Checkbox from './checkbox'

interface Props extends Omit<CheckboxProps, 'label'> {
  label?: string
  pspName: string
  drugName: string
  className?: string
}
const ConsentSection: React.FC<Props> = ({ pspName, drugName, className, ...checkboxProps }) => {
  return (
    <div
      css={{
        marginTop: 96,
        border: '3px solid #fbb600',
        padding: 16,
        borderRadius: 5,
        fontWeight: 600,
      }}
      className={className}
    >
      <Checkbox
        label={`
            Io sottoscritto/a, in qualità di Medico Specialista iscritto al ${pspName}, ricevuta richiesta da parte del mio Paziente di attivare
            i servizi inclusi nel Programma, confermo che il Paziente è idoneo a ricevere i servizi qui selezionati secondo quanto previsto dalla
            Scheda Tecnica di Prodotto ed alle indicazioni sopra fornitevi.
            Sono inoltre consapevole che i servizi sopra indicati possono essere attivati solo per i Pazienti che hanno già iniziato la terapia con
            ${drugName} e che tali servizi sono da considerarsi come addizionali e non sostitutivi a quelli in capo all'Ente o al SSN.
            `}
        {...checkboxProps}
      />
    </div>
  )
}

export default ConsentSection
