import React from 'react'

interface Props {
  error?: string
  hint?: string
}

const ErrorMessage: React.FC<Props> = ({ error, hint }) => {
  return (
    <span
      css={{
        display: 'block',
        color: error ? '#FB4600' : '#999',
        fontSize: 14,
        marginTop: 4,
        height: 14,
        width: '100%',
        textOverflow: 'ellipsis',
      }}
    >
      {error || hint}
    </span>
  )
}

export default ErrorMessage
