import React from 'react'
import RequiredFieldAlert from './required-field-alert'

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string
  label: string
  options: Array<{ label: string; value: string }>
  error?: string
  className?: string
  required?: boolean
}

const Radio: React.FC<Props> = ({ name, label, options, error, className, required, ...props }) => {
  return (
    <div css={{ marginTop: 32, marginBottom: 32 }} className={className}>
      <p css={{ color: '#000', marginBottom: 16, lineHeight: 1.2, fontWeight: 600 }}>
        {required ? <RequiredFieldAlert /> : null}
        {label}
        {error ? <span css={{ color: '#fb4600' }}> - {error}</span> : ''}
      </p>
      {options.map((opt) => (
        <label
          key={opt.value}
          css={{
            color: '#000',
            display: 'block',
            position: 'relative',
            paddingLeft: 35,
            paddingTop: 2,
            marginBottom: 12,
            marginTop: 12,
            cursor: 'pointer',
            fontSize: 16,
            userSelect: 'none',
            alignItems: 'center',
            lineHeight: 1.2,
          }}
        >
          {opt.label}
          <input
            {...props}
            id={`${name}-${opt.value}`}
            type="radio"
            name={name}
            value={opt.value}
            checked={props.value === opt.value}
            css={{
              position: 'absolute',
              opacity: 0,
              cursor: 'pointer',
              height: 0,
              width: 0,
            }}
          />
          <span
            css={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: 14,
              width: 14,
              borderRadius: '100%',
              backgroundColor: props.value === opt.value ? '#fbb600' : '#fff',
              border: '4px solid #fbb600',
            }}
          >
            <span css={{}} />
          </span>
        </label>
      ))}
    </div>
  )
}

export default Radio
