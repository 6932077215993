import styled from '@emotion/styled'
import React from 'react'

const NumberPoint = styled.span({
  borderRadius: '50%',
  display: 'inline-block',
  fontFamily: 'Lato',
  height: 25,
  width: 25,
  textAlign: 'center',
  lineHeight: 1.2,
  color: '#fff',
  backgroundColor: '#fbb600',
})

const Paragraph = styled.p({
  fontSize: '1.2rem',
  lineHeight: '1.8rem',
  marginTop: 16,
  fontFamily: 'Lato',
})

const FormIntroduction: React.FC<{}> = () => (
  <div
    css={{
      marginTop: -48,
      marginBottom: 64,
      fontSize: '1.2rem',
      border: '3px solid #fbb600',
      // borderTop: '4px solid #fbb600',
      // borderBottom: '4px solid #fbb600',
      borderRadius: 5,
      padding: 16,
      b: {
        fontWeight: 800,
      },
    }}
  >
    <h1 css={{ fontWeight: 800, fontSize: '1.5rem' }}>Istruzioni</h1>
    <Paragraph>Per attivare i servizi per un Paziente è necessario compilare il modulo sottostante.</Paragraph>
    <Paragraph>
      <b>Al termine della compilazione</b> <NumberPoint>1</NumberPoint> i dati da Lei inseriti verranno automaticamente
      inviati ad Egg, <NumberPoint>2</NumberPoint> le verrà inviata un'email di conferma al suo indirizzo email e{' '}
      <NumberPoint>3</NumberPoint> le verrà fornito un codice anonimo associato alla richiesta di attivazione dei
      servizi per il Suo Paziente.
    </Paragraph>
    <Paragraph>
      Per completare il processo di attivazione,{' '}
      <b>
        le chiediamo di fornire il codice anonimo al Suo Paziente, chiedendogli di contattarci al numero verde del
        Programma
      </b>
      . La comunicazione di tale codice da parte del Paziente è fondamentale per l'attivazione dei servizi da Lei
      indicati.
    </Paragraph>
  </div>
)

export default FormIntroduction
