import React from 'react'

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string
  label: string
  error?: string
}

const PrivacyConsent: React.FC<Props> = ({ name, label, error, ...props }) => {
  return (
    <div css={{ marginTop: 32 }}>
      <p css={{ color: '#fff', marginBottom: 16, lineHeight: 1.2 }}>
        {label}
        {error ? <span css={{ color: '#fb4600' }}> - {error}</span> : ''}
      </p>
      <label
        css={{
          color: '#fff',
          display: 'block',
          position: 'relative',
          paddingLeft: 35,
          paddingTop: 2,
          marginBottom: 24,
          marginTop: 24,
          cursor: 'pointer',
          fontSize: 16,
          userSelect: 'none',
          alignItems: 'center',
          lineHeight: 1.2,
        }}
      >
        Acconsento
        <input
          {...props}
          id={`${name}-positive`}
          type="radio"
          name={name}
          value="given"
          checked={props.value === 'given'}
          css={{
            position: 'absolute',
            opacity: 0,
            cursor: 'pointer',
            height: 0,
            width: 0,
          }}
        />
        <span
          css={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: 14,
            width: 14,
            borderRadius: '100%',
            backgroundColor: props.value === 'given' ? '#fbb600' : '#fff',
            border: '4px solid #fff',
          }}
        >
          <span css={{}} />
        </span>
      </label>
      <label
        css={{
          color: '#fff',
          display: 'block',
          position: 'relative',
          paddingLeft: 35,
          paddingTop: 2,
          marginBottom: 24,
          marginTop: 24,
          cursor: 'pointer',
          fontSize: 16,
          userSelect: 'none',
          alignItems: 'center',
          lineHeight: 1.2,
        }}
      >
        Non Acconsento
        <input
          {...props}
          id={`${name}-negative`}
          type="radio"
          name={name}
          value="notGiven"
          checked={props.value === 'notGiven'}
          css={{
            position: 'absolute',
            opacity: 0,
            cursor: 'pointer',
            height: 0,
            width: 0,
          }}
        />
        <span
          css={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: 14,
            width: 14,
            borderRadius: '100%',
            backgroundColor: props.value === 'notGiven' ? '#fbb600' : '#fff',
            border: '4px solid #fff',
          }}
        >
          <span css={{}} />
        </span>
      </label>
    </div>
  )
}

export default PrivacyConsent
