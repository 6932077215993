import React from 'react'

interface Props {
  text?: string
}

const SectionDivider: React.FC<Props> = ({ text }) => (
  <div
    css={{
      height: 96,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: -32,
      flexDirection: 'column',
    }}
  >
    {text && (
      <span css={{ fontWeight: 'normal', fontSize: 16, color: '#000', lineHeight: 1.5, textTransform: 'uppercase' }}>
        {text}
      </span>
    )}
    <div css={{ width: '70%', backgroundColor: '#fbb600', height: 2 }}></div>
  </div>
)

export default SectionDivider
