import React from 'react'

interface Props {
  pspEmail: string
  pspDrugs: string
  pspTollFreeNumber: string
  baseFontSize?: number
}

const SerivceCharacteristics: React.FC<Props> = ({ pspEmail, pspDrugs, pspTollFreeNumber, baseFontSize = 12 }) => (
  <div
    css={{
      fontSize: baseFontSize,
      lineHeight: 1.2,

      h2: {
        fontWeight: 700,
        fontSize: baseFontSize + 1,
        marginBottom: 8,
      },

      b: {
        fontWeight: 400,
      },

      p: {
        fontSize: baseFontSize,
        marginBottom: 4,
      },

      'div.paragraph': {
        fontSize: baseFontSize,
        marginBottom: 4,
      },

      ol: {
        listStyle: 'decimal',
      },

      ul: {
        listStyle: 'outside',
      },

      li: {
        margin: '8px 0',
        marginLeft: 32,
        '&.unstyled': {
          listStyle: 'none',
        },
      },
    }}
  >
    <h2>Obiettivi:</h2>
    <div className="paragraph">
      I PSP offerti hanno l'obiettivo di:
      <li className="unstyled">
        (i) migliorare la qualità della vita dei Pazienti alleviando gli impegni legati ad una terapia cronica spesso di
        tipo ospedaliero (burden of therapy);
      </li>
      <li className="unstyled">
        (ii) ridurre il peso che la gestione di una malattia cronica ha sulla vita di un individuo (burden of disease).{' '}
      </li>
      <p>
        I PSP non sono in alcun modo diretti e/o funzionali alla prescrizione, promozione, acquisto, vendita o
        pubblicità di alcun prodotto farmaceutico.
      </p>
    </div>
    <h2 css={{ marginTop: 16 }}>Condizioni generali di utilizzo del Programma:</h2>
    <ol>
      <li>
        Il Programma è erogato esclusivamente a pazienti in trattamento con {pspDrugs} e solo su richiesta del Medico
        Specialista che potrà richiedere l’attivazione di uno o più servizi in accordo agli specifici bisogni di ogni
        singolo paziente.
      </li>
      <li>
        Il Programma, supportato da Takeda Italia, è totalmente gratuito sia per i Medici e la relativa struttura
        sanitaria, e viene erogato da Egg srl società specializzata nella progettazione ed erogazione dei Programmi di
        Supporto ai pazienti.
      </li>
      <li>
        Il Programma è erogato sull'intero territorio nazionale, Città del Vaticano e Repubblica di San Marino e non
        prevede assistenza e/o reperibilità notturna.
      </li>
      <li>
        Il Medico ha il diritto in qualsiasi momento di revocare la sua adesione al Programma mediante comunicazione
        scritta ad Egg (email <a href={`mailto:${pspEmail}`}>{pspEmail}</a>) con un preavviso di almeno 20 gg prima
        della data di decorrenza della revoca stessa. Da notare che il Medico è tenuto a informare del suo recesso i
        Pazienti che si avvalgono dei servizi, infatti decorso il termine di 20 gg, il Medico non potrà più iscrivere
        nuovi Pazienti e tutti i servizi per i Pazienti da lui iscritti saranno interrotti.
      </li>
      <li>
        Il Paziente ha il diritto in qualsiasi momento di revocare la sua adesione al Programma mediante comunicazione
        scritta e/o verbale. In tali casi Egg provvederà entro un giorno lavorativo ad informare il Medico Specialista
        della volontà del Paziente.
      </li>
      <li>
        Egg, in accordo con Takeda S.p.A., si riserva il diritto di ampliare, modificare, sospendere o interrompere
        l'offerta dei servizi in qualsiasi momento dandone comunicazione scritta al Medico e al Paziente con un termine
        di preavviso di 60 giorni di calendario.
      </li>
    </ol>
    <h2 css={{ marginTop: 16 }}>Dati personali dei pazienti</h2>
    <div className="pharagraph">
      I dati personali dei Pazienti raccolti nell’esecuzione del Programma sono di proprietà dei Pazienti stessi ed Egg
      srl, in qualità di titolare, li tratterà in conformità al D.L. 196/2003 e dal nuovo Regolamento UE 2016/679. Per
      questo motivo è importante precisare che:
      <ul>
        <li>
          I dati personali dei Pazienti raccolti nell’esecuzione del Programma potranno essere condivisi con Lei previa
          autorizzazione del Paziente
        </li>
        <li>
          Potremo inviare i dati personali dei Pazienti raccolti nell’esecuzione del Programma ad altre persone da Lei
          indicate, ad esempio specialisti afferenti a centri diversi dal suo, solo se autorizzati dai Pazienti
        </li>
        <li>
          I Pazienti possono richiederci, in ogni momento, di ricevere i loro dati personali da noi raccolti
          nell’esecuzione del Programma ed usarli a proprio piacimento, ivi inclusa la possibilità di condividerli
          autonomamente con personale sanitario non afferente al Suo Centro
        </li>
      </ul>
    </div>
    <h2 css={{ marginTop: 16 }}>Parlare con Egg ed il Programma</h2>
    <div className="paragraph">
      È molto importante che Lei abbia sempre la possibilità di interfacciarsi con Egg per poter richiedere
      informazioni, variazioni negli appuntamenti, approfondimenti sui servizi o semplicemente esprimere la Sua
      opinione. Per qualsiasi necessità di chiarimento, richiesta o segnalazione in merito al servizio siamo a Sua
      disposizione dal Lunedì al Venerdì, dalle ore 9:00 alle ore 17:00, al numero verde {pspTollFreeNumber} oppure
      all’indirizzo email {pspEmail}. Poiché il Programma è erogato in accordo ad elevati standard di qualità e in
      ottica di miglioramento è a Sua disposizione un indirizzo email, qualita.psp@egg.srl, a cui è possibile segnalare
      eventuali disservizi.
    </div>
  </div>
)

export default SerivceCharacteristics
