import styled from '@emotion/styled'

const InfoBox = styled.div({
  margin: '8px 0 16px',
  minHeight: 80,
  maxHeight: 160,
  fontSize: 16,
  fontFamily: 'Lato',
  width: '100%',
  opacity: 0.6,
  border: '1px solid #fff',
  borderRadius: 3,
  padding: '8px 3px',
  boxSizing: 'content-box',
  backgroundColor: '#fff',
  color: '#000',
  overflowY: 'scroll',
})

export default InfoBox
