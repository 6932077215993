import React from 'react'

interface ClientPosition {
  topPerc: number
  bottomPerc: number
  displayPerc: number
}

interface State {
  clientRect?: ClientRect
  clientPosition?: ClientPosition
}

type Action<El extends HTMLElement> = {
  type: 'update'
  node: React.RefObject<El | null>
  windowHeight?: number
}

function reducer<El extends HTMLElement>(state: State, action: Action<El>) {
  switch (action.type) {
    case 'update':
      const node = action.node
      const clR = node?.current?.getBoundingClientRect()

      let newState: State

      if (!clR || !action.windowHeight) {
        newState = state
      } else {
        newState = {
          clientRect: clR,
          clientPosition: {
            topPerc: clR.top / action.windowHeight,
            bottomPerc: clR.bottom / action.windowHeight,
            displayPerc:
              clR.top >= action.windowHeight ? 0 : (action.windowHeight - clR.top) / (clR.height + action.windowHeight),
          },
        }
      }

      if (
        newState.clientPosition?.bottomPerc === state.clientPosition?.bottomPerc ||
        newState.clientPosition?.displayPerc === state.clientPosition?.displayPerc ||
        newState.clientPosition?.topPerc === state.clientPosition?.topPerc ||
        newState.clientRect?.top === state.clientRect?.top ||
        newState.clientRect?.bottom === state.clientRect?.bottom
      ) {
        return state
      } else {
        return newState
      }
  }
}

function useScrollPosition<El extends HTMLElement>(delay: number) {
  const node = React.useRef<El | null>(null)
  const [state, dispatch] = React.useReducer(reducer, {})
  const [windowHeight, setWindowHeight] = React.useState<number>(() => {
    if (typeof window !== 'undefined') {
      return window.innerHeight
    } else {
      return 0
    }
  })

  const handleResize = React.useCallback(() => {
    setWindowHeight(window.innerHeight)
  }, [setWindowHeight])

  const updateMeasures = React.useCallback(
    useDebounce(() => {
      dispatch({ type: 'update', node, windowHeight })
    }, delay),
    [windowHeight]
  )

  const ref = React.useCallback(
    (n: El) => {
      if (!n) {
        node.current = null
      } else {
        node.current = n
      }
      updateMeasures()
    },
    [updateMeasures]
  )

  React.useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', updateMeasures)
      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('scroll', updateMeasures)
        window.removeEventListener('resize', handleResize)
      }
    }
    return
  }, [handleResize, updateMeasures])

  return [state, ref] as [{ clientRect: ClientRect; clientPosition: ClientPosition }, (n: El | null) => void]
}

function useDebounce(funct: Function, delay: number) {
  const shouldWait = React.useRef<boolean>(false)

  return function () {
    if (!shouldWait.current) {
      shouldWait.current = true

      setTimeout(() => {
        shouldWait.current = false
        funct()
      }, delay)
    }
  }
}

export default useScrollPosition
