import React from 'react'
import styled from '@emotion/styled'
import bulletpointImg from '../../../images/bulletpoint.svg'
import mediaQueries from '../../../styles/media-queries'

const ListElement: React.FC<{}> = ({ children }) => (
  <li css={{ display: 'flex', margin: '16px 0', alignItems: 'start' }}>
    <ListElementImage src={bulletpointImg} />
    <ListElementText>{children}</ListElementText>
  </li>
)

const ListElementImage = styled.img({
  display: 'inline-block',
  marginRight: 16,
  marginTop: 8,
  height: 14,
  width: 14,
  [mediaQueries('md')]: {
    height: 18,
    width: 18,
  },
})

const ListElementText = styled.span`
  display: inline-block;
`

export default ListElement
