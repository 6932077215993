import React from 'react'
import downArrowImg from '../../images/select-down-arrow.svg'

interface SelectOption {
  label: string
  value: string
}

interface Props extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
  defaultValue?: string
  options: SelectOption[]
}

const Select: React.FC<Props> = ({ defaultValue, options, ...props }) => {
  let intOptions = options

  if (defaultValue) {
    intOptions = [{ label: defaultValue, value: '' }, ...options]
  }

  return (
    <select
      {...props}
      css={{
        height: 28,
        fontSize: 16,
        width: '100%',
        opacity: 0.6,
        padding: '2px 3px',
        boxSizing: 'content-box',
        '&:focus': {
          outline: 'none',
        },
        appearance: 'none',
        borderRadius: 3,
        background: `url(${downArrowImg}) no-repeat`,
        backgroundPosition: 'right center',
        backgroundColor: '#fff',
        color: '#000',
        border: '1px solid #fb4600',
      }}
    >
      {intOptions.map((o) => (
        <option key={o.value} value={o.value}>
          {o.label}
        </option>
      ))}
    </select>
  )
}

export default Select
