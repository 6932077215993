import React from 'react'
import Input from './input'
import Field from './field'
import { fieldErrors } from './form-helpers'
import Label from './label'
import ErrorMessage from './error-message'

const PatientLocalityField: React.FC<{ formik: any; visible?: boolean }> = ({ formik, visible = true }) => {
  if (visible) {
    return (
      <div css={{ marginTop: 80, marginBottom: -32 }}>
        <Field>
          <Label text="Comune di domicilio del Paziente" required />
          <Input {...formik.getFieldProps('patientLocality')} error={fieldErrors('patientLocality', formik)} />
          <ErrorMessage error={fieldErrors('patientLocality', formik) as string} />
        </Field>
      </div>
    )
  } else {
    return null
  }
}

export default PatientLocalityField
