import React from 'react'
import { FormikHelpers, useFormik } from 'formik'
import * as yup from 'yup'
import { NewMICIActivation } from '../../types'
import Checkbox from './checkbox'
import ErrorMessage from './error-message'
import Field from './field'
import Input from './input'
import Label from './label'
import Radio from './radio'
import SectionTitle from './section-title'
import Select from './select'
import Textarea from './textarea'
import { Container, FormSection, genOptions, fieldErrors, FormErrors } from './form-helpers'
import FormIntroduction from './form-introduction'
import SubmitButton from './submit-button'
import ConsentSection from './consent-section'
import RequestFollowupOnPatientEnrollment from './request-followup-on-patient-enrollment'
import PatientLocalityField from './patient-locality-field'

type FormValues = NewMICIActivation

interface Props {
  onSubmit: (activation: NewMICIActivation, helpers: FormikHelpers<NewMICIActivation>) => Promise<void>
  pspName: string
}

const PspMiciForm: React.FC<Props> = ({ onSubmit, pspName }) => {
  const handleSubmit = React.useCallback(
    async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      await onSubmit(values, helpers)
      helpers.setSubmitting(false)
    },
    [onSubmit]
  )

  const formik = useFormik<FormValues>({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
  })

  return (
    <Container>
      <FormIntroduction />
      <form onSubmit={formik.handleSubmit}>
        <FormSection>
          <SectionTitle>Dati anamnestici</SectionTitle>
          <Radio
            {...formik.getFieldProps('pathology')}
            error={fieldErrors('pathology', formik)}
            label="Selezionare la patologia del Paziente:"
            options={pathologyOptions}
            required
          />
        </FormSection>
        <FormSection>
          <SectionTitle css={{ marginTop: 32 }}>Training all'autosomministrazione</SectionTitle>
          <Checkbox
            {...formik.getFieldProps('trainingService')}
            label="Attivare il servizio di training all'autosomministrazione"
            error={fieldErrors('trainingService', formik)}
          />
          {formik.values.trainingService && (
            <React.Fragment>
              <Radio
                {...formik.getFieldProps('posology')}
                error={fieldErrors('posology', formik)}
                label="Posologia"
                options={posologyOptions}
                required
              />
              {formik.values.posology === 'Altro' && (
                <Field css={{ marginTop: 16, marginBottom: -16 }}>
                  <Label text="Indicare la posologia" required />
                  <Input {...formik.getFieldProps('posologyOther')} />
                  <ErrorMessage error={fieldErrors('posologyOther', formik)} />
                </Field>
              )}
              <Radio
                {...formik.getFieldProps('formulation')}
                error={fieldErrors('formulation', formik)}
                label="Formulazione"
                options={formulationOptions}
                required
              />
              <Radio
                {...formik.getFieldProps('firstSessionDateOption')}
                error={fieldErrors('firstSessionDateOption', formik)}
                label="Data della prima sessione di training"
                options={firstSessionDateOptions}
                required
              />
              {formik.values.firstSessionDateOption === 'In una data specifica' && (
                <React.Fragment>
                  <Field css={{ marginTop: 16, marginBottom: -16 }}>
                    <Label text="Indicare la data della prima somministrazione" required />
                    <Input {...formik.getFieldProps('firstSessionDate')} />
                    <ErrorMessage error={fieldErrors('firstSessionDate', formik)} />
                  </Field>
                  <Field css={{ marginTop: 16, marginBottom: -16 }}>
                    <Label text="Indicare, in caso di necessità del paziente, un range di tolleranza per lo spostamento della prima somministrazione (es. +/-2 giorni)" />
                    <Input {...formik.getFieldProps('administrationTollerance')} />
                    <ErrorMessage error={fieldErrors('administrationTollerance', formik)} />
                  </Field>
                </React.Fragment>
              )}
              <div css={{ marginTop: 32 }}>
                <Field>
                  <Label text="Il Training è di norma effettuato da remoto con connessione audio-video. Si richiede che la formazione si svolga invece di persona?" />
                  <Select {...formik.getFieldProps('inPersonSession')} options={yesNoOptions} />
                  <ErrorMessage error={fieldErrors('inPersonSession', formik)} />
                </Field>
                {formik.values.inPersonSession === 'Si' && (
                  <React.Fragment>
                    <Field>
                      <Label text="Indicare il luogo di svolgimento della prima formazione" required />
                      <Select
                        {...formik.getFieldProps('firstInPersonSessionLocation')}
                        options={firstInPersonSessionLocationOptions}
                      />
                      <ErrorMessage error={fieldErrors('firstInPersonSessionLocation', formik)} />
                    </Field>
                    <div css={{ marginTop: -16, color: '#fb4600', fontSize: 14 }}>
                      <span>
                        {formik.values.firstInPersonSessionLocation === 'Al Centro'
                          ? "Attenzione, per concordare data, ora e luogo per l'appuntamento al Centro è necessario contattare Egg via email oppure al numero verde"
                          : "Attenzione, l'esecuzione del training è subordinata all'adesione del Paziente tramite telefonata al numero verde. Il tempo minimo di preavviso è di 3 giorni lavorativi"}
                      </span>
                    </div>
                  </React.Fragment>
                )}
              </div>
              <Checkbox
                {...formik.getFieldProps('shouldReceiveStarterKit')}
                label="Consegnare al Paziente un kit dimostrativo che lo aiuterà ad esercitarsi nella corretta pratica dell’autosomministrazione"
                error={fieldErrors('shouldReceiveStarterKit', formik)}
              />
            </React.Fragment>
          )}
        </FormSection>
        <FormSection>
          <SectionTitle css={{ marginTop: 32 }}>Counseling Psicologico</SectionTitle>
          <Checkbox
            {...formik.getFieldProps('counselingService')}
            label="Attivare il servizio di counseling psicologico"
            error={fieldErrors('counselingService', formik)}
          />
          {formik.values.counselingService && (
            <React.Fragment>
              <Label text="Opzioni del servizio" css={{ marginTop: 32 }} />
              <Checkbox
                {...formik.getFieldProps('firstCounselingSessionWithHCP')}
                label="Desidero partecipare alla prima sessione preliminare tra il Paziente e lo Psicologo per essere coinvolto nella definizione degli obiettivi di counseling (la sessione si svolge in video conferenza)"
                error={fieldErrors('firstCounselingSessionWithHCP', formik)}
              />
              <Checkbox
                {...formik.getFieldProps('counselingHCPReport')}
                label="Al termine del percorso di counseling desidero ricevere una relazione da parte dello Psicologo (l'invio di una relazione conclusiva è soggetta al consenso da parte del Paziente)"
                error={fieldErrors('counselingHCPReport', formik)}
              />
            </React.Fragment>
          )}
        </FormSection>
        <FormSection>
          <SectionTitle css={{ marginTop: 32 }}>Corso Mindfulness</SectionTitle>
          <Checkbox
            {...formik.getFieldProps('mindfulnessService')}
            label="Attivare il servizio di corso mindfulness"
            error={fieldErrors('mindfulnessService', formik)}
          />
          {formik.values.mindfulnessService && (
            <React.Fragment>
              <Label text="Opzioni del servizio" css={{ marginTop: 32 }} />
              <Checkbox
                {...formik.getFieldProps('firstMindfulnessSessionWithHCP')}
                label="Desidero partecipare alla prima sessione preliminare tra il Paziente e lo Psicologo per essere coinvolto nella condivisione degli obiettivi del corso (la sessione si svolge in video conferenza)"
                error={fieldErrors('firstMindfulnessSessionWithHCP', formik)}
              />
              <Checkbox
                {...formik.getFieldProps('mindfulnessHCPReport')}
                label="Al termine del corso di mindfulness desidero ricevere una relazione da parte dello Psicologo"
                error={fieldErrors('mindfulnessHCPReport', formik)}
              />
            </React.Fragment>
          )}
        </FormSection>
        <FormSection>
          <SectionTitle css={{ marginTop: 32 }}>Supporto Nutrizionale</SectionTitle>
          <Checkbox
            {...formik.getFieldProps('nutritionalService')}
            label="Attivare il servizio di supporto nutrizionale"
            error={fieldErrors('nutritionalService', formik)}
          />
          {formik.values.nutritionalService && (
            <React.Fragment>
              <Label text="Opzioni del servizio" css={{ marginTop: 32 }} />
              <Checkbox
                {...formik.getFieldProps('firstNutritionalSessionWithHCP')}
                label="Desidero partecipare alla prima sessione tra il Paziente e il Nutrizionista per essere coinvolto nella definizione degli obiettivi del supporto e potermi confrontare con il Nutrizionista (la sessione si svolge in video conferenza)"
                error={fieldErrors('firstNutritionalSessionWithHCP', formik)}
              />
              <Checkbox
                {...formik.getFieldProps('requestNutritionistCall')}
                label="Desidero essere contattato dal Nutrizionista prima dell’invio delle linee guida al Paziente"
                error={fieldErrors('requestNutritionistCall', formik)}
              />
              <Checkbox
                {...formik.getFieldProps('requestNutritionalGuidelines')}
                label="Desidero ricevere le linee guida che verranno inviate al Paziente"
                error={fieldErrors('requestNutritionalGuidelines', formik)}
              />
              <Radio
                {...formik.getFieldProps('illnessDuration')}
                error={fieldErrors('illnessDuration', formik)}
                label="Durata della malattia"
                options={illnessDurationOptions}
                required
              />
              <Radio
                {...formik.getFieldProps('illnessActivity')}
                error={fieldErrors('illnessActivity', formik)}
                label="Attività attuale della malattia"
                options={illnessActivityOptions}
                required
              />
              <Field css={{ marginTop: 32 }}>
                <Label text="Altre eventuali patologie associate, e/o non associate (es. Artrite Reumatoide, Celiachia, Tiroidite di Hashimoto, o Altra)" />
                <Textarea {...formik.getFieldProps('otherPathologies')} />
                <ErrorMessage error={fieldErrors('otherPathologies', formik)} />
              </Field>
              <Radio
                {...formik.getFieldProps('bowelResection')}
                error={fieldErrors('bowelResection', formik)}
                label="Resezione intestino tenue"
                options={bowelResectionOptions}
                css={{ marginTop: 0 }}
                required
              />
              <Radio
                {...formik.getFieldProps('colonResection')}
                error={fieldErrors('colonResection', formik)}
                label="Resezione colon"
                options={yesNoOptions}
                required
              />
              <Radio
                {...formik.getFieldProps('ileusResection')}
                error={fieldErrors('ileusResection', formik)}
                label="Resezione ileo-colica"
                options={ileusResectionOptions}
                required
              />
              <Radio
                {...formik.getFieldProps('ostomy')}
                error={fieldErrors('ostomy', formik)}
                label="Stomia"
                options={ostomyOptions}
                required
              />
              <Radio
                {...formik.getFieldProps('pouch')}
                error={fieldErrors('pouch', formik)}
                label="Pouch"
                options={yesNoOptions}
                required
              />
              <Radio
                {...formik.getFieldProps('fistula')}
                error={fieldErrors('fistula', formik)}
                label="Fistola/e perianali"
                options={yesNoOptions}
                required
              />
              <Radio
                {...formik.getFieldProps('weightLoss')}
                error={fieldErrors('weightLoss', formik)}
                label="Calo ponderale (rispetto al peso abituale) negli ultimi 3 mesi"
                options={weightLossOptions}
                required
              />
              <Checkbox
                {...formik.getFieldProps('preEntyvioInformations')}
                label="Le indicazioni sanitarie fornite sono antecedenti e non correlate all’uso di Entyvio"
                error={fieldErrors('preEntyvioInformations', formik)}
                css={{ marginTop: 32 }}
              />
            </React.Fragment>
          )}
        </FormSection>
        <FormSection>
          <SectionTitle css={{ marginTop: 32 }}>Consegna domiciliare del farmaco</SectionTitle>
          <Checkbox
            {...formik.getFieldProps('drugDeliveryService')}
            label="Attivare il servizio di consegna domiciliare del farmaco"
            error={fieldErrors('drugDeliveryService', formik)}
          />
          {formik.values.drugDeliveryService && (
            <React.Fragment>
              <Radio
                label="Farmacia per il ritiro"
                {...formik.getFieldProps('pharmacyOptions')}
                options={pharmacyOptions}
                error={fieldErrors('pharmacyOptions', formik)}
                required
              />
              {formik.values.pharmacyOptions === 'Gli estremi della Farmacia sono i seguenti' && (
                <Field css={{ marginTop: 16 }}>
                  <Label text="Indicare gli estremi della Farmacia" required />
                  <Textarea
                    {...formik.getFieldProps('pharmacyInformations')}
                    placeholder="Nome, contatto telefonico, email, indirizzo, eventuale persona di riferimento, ..."
                  />
                  <ErrorMessage error={fieldErrors('pharmacyInformations', formik)} />
                </Field>
              )}
            </React.Fragment>
          )}
        </FormSection>
        <PatientLocalityField formik={formik} visible={formik.values.trainingService} />
        <RequestFollowupOnPatientEnrollment formik={formik} />
        <ConsentSection
          {...formik.getFieldProps('physicianConfirmation')}
          pspName={pspName}
          drugName="Entyvio SC"
          error={fieldErrors('physicianConfirmation', formik)}
        />
        <SubmitButton formik={formik} />
        <FormErrors formik={formik} />
      </form>
    </Container>
  )
}

const pathologyValues = ['Malattia di Crohn', 'Rettocolite Ulcerosa', 'Colite Indeterminata/IBD non classificata']
const pathologyOptions = genOptions(pathologyValues)

const posologyValues = [
  'Come previsto da RCP (108 mg somministrati mediante iniezione sottocutanea una volta ogni 2 settimane)',
  'Altro',
]
const posologyOptions = genOptions(posologyValues)

const formulationValues = ['Siringa', 'Penna']
const formulationOptions = genOptions(formulationValues)

const firstSessionDateValues = ['Da definire con il Paziente', 'In una data specifica']
const firstSessionDateOptions = genOptions(firstSessionDateValues)

const firstInPersonSessionLocationValues = ['Al Centro', 'Al domicilio']
const firstInPersonSessionLocationOptions = genOptions(firstInPersonSessionLocationValues)

const illnessDurationValues = ['Meno di un anno', 'Da uno a tre anni', 'Da tre a dieci anni', 'Più di dieci anni']
const illnessDurationOptions = genOptions(illnessDurationValues)

const illnessActivityValues = ['Recidiva', 'Remissione']
const illnessActivityOptions = genOptions(illnessActivityValues)

const bowelResectionValues = ['Si, maggiore di 20 cm', 'Si, minore di 20 cm', 'No']
const bowelResectionOptions = genOptions(bowelResectionValues)

const yesNoValues = ['Si', 'No']
const yesNoOptions = genOptions(yesNoValues)

const ileusResectionValues = ['Si, maggiore di 20 cm', 'Si, minore di 20 cm', 'No']
const ileusResectionOptions = genOptions(ileusResectionValues)

const ostomyValues = ['Si, ileale', 'Si, colica', 'No']
const ostomyOptions = genOptions(ostomyValues)

const weightLossValues = ['Compreso tra il 5% ed il 10%', "Compreso tra l'11% ed il 20%", 'Maggiore del 20%']
const weightLossOptions = genOptions(weightLossValues)

const pharmacyValues = [
  'Il Paziente conosce gli estremi della Farmacia presso cui effettuare il ritiro della terapia',
  'Gli estremi della Farmacia sono i seguenti',
]
const pharmacyOptions = genOptions(pharmacyValues)

const validationSchema = yup.object({
  pathology: yup.string().required('Campo obbligatorio').oneOf(pathologyValues, 'Inserire un valore corretto'),

  trainingService: yup
    .boolean()
    .when(['counselingService', 'mindfulnessService', 'nutritionalService', 'drugDeliveryService'], {
      is: (cS: boolean, mS: boolean, nS: boolean, dDs: boolean) => !cS && !mS && !nS && !dDs,
      then: yup.boolean().oneOf([true], "E' necessario attivare almeno un servizio"),
      otherwise: yup.boolean(),
    }),
  posology: yup.string().when('trainingService', {
    is: true,
    then: yup.string().required('Campo obbligatorio').oneOf(posologyValues, 'Inserire un valore corretto'),
    otherwise: yup.string().notRequired(),
  }),
  posologyOther: yup.string().when('posology', {
    is: 'Altro',
    then: yup.string().required('Campo obbligatorio'),
    otherwise: yup.string().notRequired(),
  }),
  formulation: yup.string().when('trainingService', {
    is: true,
    then: yup.string().required('Campo obbligatorio').oneOf(formulationValues, 'Inserire un valore corretto'),
    otherwise: yup.string().notRequired(),
  }),
  firstSessionDateOption: yup.string().when('trainingService', {
    is: true,
    then: yup.string().required('Campo obbligatorio').oneOf(firstSessionDateValues, 'Inserire il valore corretto'),
    otherwise: yup.string().notRequired(),
  }),
  firstSessionDate: yup.string().when('firstSessionDateOption', {
    is: 'In una data specifica',
    then: yup.string().required('Campo obbligatorio'),
    otherwise: yup.string().notRequired(),
  }),
  administrationTollerance: yup.string().notRequired(),
  inPersonSession: yup.string().when('trainingService', {
    is: true,
    then: yup.string().required('Campo obbligatorio').oneOf(yesNoValues, 'Inserire il valore corretto'),
    otherwise: yup.string().notRequired(),
  }),
  firstInPersonSessionLocation: yup.string().when('inPersonSession', {
    is: true,
    then: yup
      .string()
      .required('Campo obbligatorio')
      .oneOf(firstInPersonSessionLocationValues, 'Inserire il valore corretto'),
  }),
  shouldReceiveStarterKit: yup.boolean(),

  counselingService: yup.boolean(),
  firstCounselingSessionWithHCP: yup.boolean(),
  counselingHCPReport: yup.boolean(),

  mindfulnessService: yup.boolean(),
  firstMindfulnessSessionWithHCP: yup.boolean(),
  mindfulnessHCPReport: yup.boolean(),

  nutritionalService: yup.boolean(),
  firstNutritionalSessionWithHCP: yup.boolean(),
  requestNutritionistCall: yup.boolean(),
  requestNutritionalGuidelines: yup.boolean(),
  illnessDuration: yup.string().when('nutritionalService', {
    is: true,
    then: yup.string().required('Campo obbligatorio').oneOf(illnessDurationValues, 'Inserire un valore corretto'),
    otherwise: yup.string().notRequired(),
  }),
  illnessActivity: yup.string().when('nutritionalService', {
    is: true,
    then: yup.string().required('Campo obbligatorio').oneOf(illnessActivityValues, 'Inserire un valore corretto'),
    otherwise: yup.string().notRequired(),
  }),
  otherPathologies: yup.string().notRequired(),
  bowelResection: yup.string().when('nutritionalService', {
    is: true,
    then: yup.string().required('Campo obbligatorio').oneOf(bowelResectionValues, 'Inserire un valore corretto'),
    otherwise: yup.string().notRequired(),
  }),
  colonResection: yup.string().when('nutritionalService', {
    is: true,
    then: yup.string().required('Campo obbligatorio').oneOf(yesNoValues, 'Inserire un valore corretto'),
    otherwise: yup.string().notRequired(),
  }),
  ileusResection: yup.string().when('nutritionalService', {
    is: true,
    then: yup.string().required('Campo obbligatorio').oneOf(ileusResectionValues, 'Inserire un valore corretto'),
    otherwise: yup.string().notRequired(),
  }),
  ostomy: yup.string().when('nutritionalService', {
    is: true,
    then: yup.string().required('Campo obbligatorio').oneOf(ostomyValues, 'Inserire un valore corretto'),
    otherwise: yup.string().notRequired(),
  }),
  pouch: yup.string().when('nutritionalService', {
    is: true,
    then: yup.string().required('Campo obbligatorio').oneOf(yesNoValues, 'Inserire un valore corretto'),
    otherwise: yup.string().notRequired(),
  }),
  fistula: yup.string().when('nutritionalService', {
    is: true,
    then: yup.string().required('Campo obbligatorio').oneOf(yesNoValues, 'Inserire un valore corretto'),
    otherwise: yup.string().notRequired(),
  }),
  weightLoss: yup.string().when('nutritionalService', {
    is: true,
    then: yup.string().required('Campo obbligatorio').oneOf(weightLossValues, 'Inserire un valore corretto'),
    otherwise: yup.string().notRequired(),
  }),
  preEntyvioInformations: yup.boolean(),

  drugDeliveryService: yup.boolean(),
  pharmacyOptions: yup.string().when('drugDeliveryService', {
    is: true,
    then: yup.string().required('Campo obbligatorio').oneOf(pharmacyValues, 'Inserire il valore corretto'),
    otherwise: yup.string().notRequired(),
  }),
  pharmacyInformations: yup.string().when('pharmacyOptions', {
    is: 'Gli estremi della Farmacia sono i seguenti',
    then: yup.string().required('Campo obbligatorio'),
    otherwise: yup.string().notRequired(),
  }),

  requestFollowupOnPatientEnrollment: yup.boolean(),

  patientLocality: yup.string().when('trainingService', {
    is: true,
    then: yup.string().required('Campo obbligatorio'),
    otherwise: yup.string().notRequired(),
  }),

  physicianConfirmation: yup
    .boolean()
    .oneOf([true], "Senza conferma non è possibile procedere con l'invio della richiesta di attivazione dei servizi"),
})

const initialValues: FormValues = {
  pathology: '',
  patientLocality: '',

  trainingService: false,
  posology: posologyValues[0],
  posologyOther: '',
  formulation: '',
  firstSessionDateOption: '',
  firstSessionDate: '',
  administrationTollerance: '',
  inPersonSession: 'No',
  firstInPersonSessionLocation: 'Al domicilio',
  shouldReceiveStarterKit: false,

  counselingService: false,
  firstCounselingSessionWithHCP: false,
  counselingHCPReport: false,

  mindfulnessService: false,
  firstMindfulnessSessionWithHCP: false,
  mindfulnessHCPReport: false,

  nutritionalService: false,
  firstNutritionalSessionWithHCP: false,
  requestNutritionistCall: false,
  requestNutritionalGuidelines: false,
  illnessDuration: '',
  illnessActivity: '',
  otherPathologies: '',
  bowelResection: '',
  colonResection: '',
  ileusResection: '',
  ostomy: '',
  pouch: '',
  fistula: '',
  weightLoss: '',
  preEntyvioInformations: false,

  drugDeliveryService: false,
  pharmacyOptions: '',
  pharmacyInformations: '',

  // Follow-up on Patient erollment
  requestFollowupOnPatientEnrollment: false,

  physicianConfirmation: false,
}

export default PspMiciForm
