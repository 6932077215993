import React from 'react'
import { Routes, Route } from 'react-router-dom'
import SuccessPage from './components/success-page'
import ErrorPage from './components/error-page'
import VerifyCode from './components/verify-code'
import PatientPrivacyPage from './components/patient-privacy-page'
import NewServiceActivationPage from './components/new-service-activation-page'
import WrongCodeErrorMessage from './components/wrong-code-error-message'

const Routing: React.FC<{}> = () => {
  return (
    <Routes>
      <Route path="service-activations/:subscriptionId" element={<NewServiceActivationPage />} />
      <Route path="privacy-paziente/:privacyCode" element={<PatientPrivacyPage />} />
      <Route path="success" element={<SuccessPage />} />
      <Route path=":code" element={<VerifyCode />} />
      <Route
        path="/*"
        element={
          <ErrorPage>
            <WrongCodeErrorMessage />
          </ErrorPage>
        }
      />
    </Routes>
  )
}

export default Routing
