import React from 'react'

const WrongCodeErrorMessage: React.FC<{}> = () => (
  <p css={{ textAlign: 'center', marginTop: 32 }}>
    per accedere alla pagina corretta e aderire al Programma di Supporto al Paziente contattare{' '}
    <b>Takeda Italia S.p.A. all’indirizzo psp-italia@takeda.com</b>
  </p>
)

export default WrongCodeErrorMessage
