import React from 'react'
import StepsDescription from './steps-description'
import { Psp } from '../../types'
import Welcome from './welcome'

interface Props {
  psp: Psp
}

const SubscriptionContent: React.FC<Props> = ({ psp }) => {
  return (
    <div css={{ maxWidth: 960, margin: '0 auto', marginBottom: 80 }}>
      <Welcome psp={psp} />
      <StepsDescription psp={psp} />
    </div>
  )
}

export default SubscriptionContent
