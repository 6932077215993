import React from 'react'
import styled from '@emotion/styled'
import successImgXl from '../images/success-xl.jpg'
import successImgLg from '../images/success-lg.jpg'
import successImgMd from '../images/success-md.jpg'
import successImgSm from '../images/success-sm.jpg'
import logoEgg from '../images/logo-egg.svg'
import mediaQueries from '../styles/media-queries'
import thankYouImg from '../images/thank-you.svg'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'

const SuccessPage: React.FC<{}> = () => {
  const { search } = useLocation()
  const params = qs.parse(search)

  return (
    <Wrapper>
      <LogoContainer>
        <CompanyLogo alt="Logo Egg" src={logoEgg} />
      </LogoContainer>
      <BoxContainer>
        <ThankYouImg alt="Thank You" src={thankYouImg} />
        <div css={{ marginTop: 32, fontSize: 18, lineHeight: 1.3, width: '100%', b: { fontWeight: 700 } }}>
          <p css={{ textAlign: 'center' }}>
            La ringraziamo, a breve <b>riceverà una mail di conferma</b> con il testo dell’Informativa Privacy e le
            schede con la descrizione dei servizi.
          </p>
          <p css={{ textAlign: 'center', marginTop: 32 }}>
            Qualora non la ricevesse in pochi minuti La invitiamo a controllare nella cartella di Posta Indesiderata
            (Spam).
          </p>
          <p css={{ textAlign: 'center', marginTop: 32, marginBottom: 32 }}>
            Per qualsiasi necessità siamo a Sua disposizione all’indirizzo mail{' '}
            <a href={`mailto:${params.email}`}>{params.email}</a>, e dal Lunedì al Venerdì dalle ore 09:00 alle ore
            17:00 al numero verde dedicato <b>{params.tollFreeNumber}</b> (festivi esclusi).
          </p>
        </div>
      </BoxContainer>
      <div css={{ color: '#fff', width: '100%', justifySelf: 'end', marginBottom: 32, marginTop: 32, display: 'flex' }}>
        <span css={{ marginLeft: 'auto', marginRight: 64 }}>Egg Srl &trade; 2020</span>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div({
  width: '100%',
  minHeight: '100vh',
  backgroundImage: `url(${successImgSm})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#fbb600',
  [mediaQueries('sm')]: {
    backgroundImage: `url(${successImgSm})`,
  },
  [mediaQueries('md')]: {
    backgroundImage: `url(${successImgMd})`,
  },
  [mediaQueries('lg')]: {
    backgroundImage: `url(${successImgLg})`,
  },
  [mediaQueries('xl')]: {
    backgroundImage: `url(${successImgXl})`,
  },
})

const LogoContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  padding: '0 20px',
  width: '100%',
  boxSizing: 'border-box',
  [mediaQueries('sm')]: {
    padding: '0 20px',
  },
  [mediaQueries('md')]: {
    padding: '0 40px',
  },
  [mediaQueries('lg')]: {
    padding: '0 70px',
  },
  [mediaQueries('xl')]: {
    padding: '0 100px',
  },
})

const CompanyLogo = styled.img({
  width: 120,
  height: 120,
  [mediaQueries('sm')]: {
    width: 150,
    height: 150,
  },
  [mediaQueries('md')]: {
    width: 180,
    height: 180,
  },
  [mediaQueries('lg')]: {
    width: 200,
    height: 200,
  },
  [mediaQueries('xl')]: {
    width: 250,
    height: 250,
  },
})

const BoxContainer = styled.div({
  boxSizing: 'border-box',
  backgroundColor: '#fff',
  opacity: 0.7,
  padding: 16,
  margin: 16,
  maxWidth: 960,
  borderRadius: 20,
  height: '100%',
  width: '90%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [mediaQueries('md')]: {
    padding: 32,
    margin: 32,
  },
})

const ThankYouImg = styled.img({
  height: '100%',
  width: '90%',
  [mediaQueries('sm')]: {
    width: '80%',
    maxWidth: 400,
  },
  [mediaQueries('md')]: {
    width: 500,
    maxWidth: 500,
  },
  [mediaQueries('lg')]: {
    width: 600,
    maxWidth: 600,
  },
})

export default SuccessPage
