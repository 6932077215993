import React from 'react'

interface Props {
  baseFontSize?: number
}

const PrivacyDisclaimer: React.FC<Props> = ({ baseFontSize = 12 }) => (
  <div
    css={{
      h1: {
        fontWeight: 700,
        fontSize: baseFontSize + 2,
        marginBottom: 8,
        lineHeight: 1.2,
      },
      h2: {
        fontWeight: 700,
        fontSize: baseFontSize + 1,
        marginTop: 16,
        marginBottom: 8,
        lineHeight: 1.2,
      },
      b: {
        fontWeight: 400,
      },
      p: {
        fontSize: baseFontSize,
        marginBottom: 4,
        lineHeight: 1.2,
      },
      'ul, ol': {
        fontSize: baseFontSize,
        margin: '8px 8px',
        lineHeight: 1.2,
      },
      li: {
        margin: '4px 0',
      },
    }}
  >
    <h1>
      INFORMATIVA MEDICO n. TKM2107 SULLA PROTEZIONE DEI DATI PERSONALI AI SENSI DEL REGOLAMENTO UE 2016/679 (“GDPR”) E
      DEL D.LGS. 196/2003, COME MODIFICATO DAL D.LGS. N. 101/2018
    </h1>
    <h2>Premessa</h2>
    <p>
      Egg S.r.l., con sede legale in Milano (MI), Via Visconti di Modrone nr. 30 – Codice Fiscale e P. Iva 09477660964
      (qui di seguito “EGG”) è responsabile del Programma di supporto al paziente (qui di seguito Programma) promosso
      dalla società Takeda Italia S.p.A., con sede legale in Roma, Via Elio Vittorini 129, C.F. e Partita IVA
      00696360155 (qui di seguito “Takeda”).
    </p>
    <p>
      EGG con la presente intende metterle a disposizione alcune informazioni circa il trattamento dei dati personali
      che La riguardano.
    </p>
    <h2>1. Categorie di dati personali trattati da EGG</h2>
    <p>
      I dati personali oggetto di trattamento da parte di EGG ai fini del loro trattamento nell’ambito del Programma
      sono i seguenti:
    </p>
    <ol>
      <li>i) i dati anagrafici e di contatto (nome, cognome, contatti telefonici, email);</li>
      <li>ii) dati relativi alla specializzazione, al reparto ed al Centro Clinico di riferimento</li>
    </ol>
    <p>
      (qui di seguito i "<b>Dati Personali</b>”).
    </p>
    <h2>2. Finalità e base giuridica del trattamento</h2>
    <p>
      2.1 I Dati Personali saranno utilizzati esclusivamente per consentire l’esecuzione e l’adempimento di tutti gli
      obblighi derivanti dalla sua partecipazione al Programma ed, in particolare, per le seguenti finalità:
    </p>
    <ol>
      <li>a) organizzazione e gestione dei servizi oggetto del Programma;</li>
      <li>
        b) invio di comunicazioni di servizio e materiale informativo relativo al Programma tramite contatto telefonico,
        invio di sms o comunicazioni email;
      </li>
      <li>c) esecuzione degli adempimenti di farmacovigilanza;</li>
      <li>d) gestione di eventuali attività logistiche inerenti il Programma;</li>
      <li>
        e) finalità statistiche per la valutazione dell’andamento del Programma, tra cui:
        <ol>
          <li>a. questionari per valutare la soddisfazione o il gradimento dei servizi offerti</li>
          <li>b. indagini mirate a raccogliere l’opinione dei medici su possibili nuovi servizi</li>
          <li>c. report di monitoraggio dei servizi erogati </li>
        </ol>
        I risultati di queste indagini potranno essere condivisi da Egg con Takeda Italia S.p.A., società farmaceutica
        sponsor del programma, unicamente in modalità aggregata ed anonimizzata, attuando logiche che garantiscano
        l’impossibilità di risalire anche indirettamente a Lei.
      </li>
      <li>
        f) trasferimento all’Azienda sponsor del Programma e/o eventuali nuovi Provider per assicurare la continuità dei
        servizi oggetto del Programma in caso di affidamento degli stessi a un nuovo provider;
      </li>
    </ol>
    <p>2.2 La base giuridica del trattamento:</p>
    <ul>
      <li>
        - per le finalità di cui alle lettere da a) a d) risiede nella sua adesione al Programma e nell’esecuzione degli
        obblighi contrattuali e end-contrattuali per la realizzazione del Programma.
      </li>
      <li>
        - per le finalità di cui alla lettera e) è il suo consenso, il quale le verrà richiesto in sede di
        sottoscrizione ed adesione al Programma;
      </li>
      <li>
        - per la finalità di cui alla lettera f) la base giuridica risiede nella adesione al Programma e alla
        contestuale accettazione della cessione del contratto in caso di trasferimento dei servizi ad un nuovo provider,
        al fine di garantire la gestione in continuità dei servizi di cui al Programma;
      </li>
    </ul>
    <h2>3. Natura del conferimento dei dati</h2>
    <p>
      Il trattamento dei suoi Dati Personali è necessario per le finalità indicate al precedente articolo 2 da lettera
      (a) a (d) ed (f) ai fini della partecipazione al Programma ed all’espletamento degli obblighi di legge; per tale
      ragione, un eventuale rifiuto a conferire i dati può comportare l’impossibilità di erogare i servizi oggetto del
      Programma.
    </p>
    <p>
      Il suo consenso per le finalità indicate al precedente articolo 2 lettera (e) è facoltativo ed il mancato
      conferimento non inibirà la corretta esecuzione del Programma a cui ha deciso di aderire.
    </p>
    <h2>4. Modalità del trattamento </h2>
    <p>
      Il trattamento dei Dati Personali è realizzato sia in modalità cartacea che con l’ausilio di mezzi elettronici e/o
      informatici.
    </p>
    <p>I Dati Personali saranno conservati su server ubicati in Europa.</p>
    <h2>5. Titolare del Trattamento</h2>
    <p>
      Il Titolare del trattamento dei suoi Dati Personali è EGG S.r.l., con sede legale in Milano (MI), Via Visconti di
      Modrone nr. 30 - Codice Fiscale e P. Iva 09477660964, che potrà essere contattata al numero di fax +39 0287348245
      - email info@egg.srl
    </p>
    <h2>6. Ambito di comunicazione dei Dati Personali</h2>
    <p>
      Il Titolare del Trattamento raccoglierà e gestirà i Dati Personali attraverso soggetti e/o responsabili
      debitamente autorizzati a svolgere le attività ed i servizi relativi al Programma ai quali sono state fornite
      tutte le istruzioni da seguire al fine di garantire elevati livelli di sicurezza e riservatezza. In particolare,
      tali soggetti utilizzeranno strumenti e supporti - cartacei, magnetici, informatici o telematici – idonei a
      garantire la sicurezza e la riservatezza. I Dati Personali acquisiti e/o raccolti dal EGG potranno essere oggetto
      di comunicazione ai soggetti la cui facoltà di accedere ai dati sia riconosciuta dalla legge ed alle categorie di
      soggetti di seguito illustrate.
    </p>
    <h2>7. Comunicazione dei dati</h2>
    <p>I Dati Personali potranno essere comunicati, nei limiti delle finalità illustrate, a:</p>
    <ol>
      <li>
        i) operatori sanitari (quali a titolo esemplificativo e non esaustivo Medico, Infermiere, Fisioterapista,
        Psicologo) e/o consulenti e/o professionisti di volta in volta coinvolti da EGG nell’ambito della realizzazione
        del Programma;
      </li>
      <li>
        ii) dipendenti, a vario titolo, di EGG quali, a titolo esemplificativo e non esaustivo Project Manager -
        Assistant Project Manager - Operatori Contact Center, in qualità di soggetti autorizzati;
      </li>
      <li>
        iii) Soggetti specializzati nella realizzazione di esami diagnostici quali a titolo esemplificativo e non
        esaustivo Laboratori analisi, Società di Telemedicina di volta in volta coinvolti da EGG nell’ambito della
        realizzazione del Programma;
      </li>
      <li>
        iv) Soggetti specializzati nella distribuzione logistica di farmaci e/o materiali legati al Programma di volta
        in volta coinvolti da EGG nell’ambito della realizzazione del Programma;
      </li>
      <li>v) Azienda promotore del Programma;</li>
      <li>
        vi) soggetti tenuti a conoscere dei dati personali a lei riferibili nell’ambito delle disposizioni in materia di
        Farmacovigilanza (azienda promotore del Programma), quindi nel caso del verificarsi di eventi avversi che, in
        forza di specifiche disposizioni di legge, impongono la comunicazione dei dati personali, inclusi eventualmente
        anche i dati particolari. Questa comunicazione è obbligatoria ai sensi di legge e del codice deontologico
        Farmindustria
      </li>
    </ol>
    <p>
      Nel caso di consenso al trasferimento (sub 2.1. lettera f), i suoi dati personali, nessuno escluso, saranno
      trasferiti all’Azienda promotore del Programma e/o a nuovi Provider che li tratteranno, in qualità di autonomi
      titolari del trattamento, per garantire la prosecuzione in continuità dei servizi oggetto del Programma in caso di
      affidamento degli stessi a un nuovo provider.
    </p>
    <h2>8. Periodo di conservazione</h2>
    <p>
      I suoi Dati Personali saranno conservati in una forma che consenta la sua identificazione per un arco temporale
      non superiore al conseguimento delle finalità per il quale gli stessi sono raccolti e trattati in conformità agli
      obblighi di legge e, comunque, per un periodo non superiore a <b>30 giorni</b> dalla fine per qualsiasi ragione
      del Programma o dalla cessazione della sua adesione al Programma medesimo. Decorso detto termine, i suoi Dati
      Personali non saranno più trattati e saranno cancellati, salvo l’adempimento degli obblighi legali.
    </p>
    <h2>9. Diritti dell’interessato</h2>
    <p>
      Relativamente ai Dati Personali Lei potrà mediante invio di comunicazione scritta a mezzo raccomandata a/r ad EGG
      S.r.l., con sede legale in Milano (MI), Via Visconti di Modrone nr. 30 – Codice Fiscale e P. Iva 09477660964
      oppure all’indirizzo email info@egg.srl:
    </p>
    <ol>
      <li>
        i) chiedere ad EGG, quale titolare del trattamento l’accesso ai suoi Dati Personali, la rettifica o la
        cancellazione degli stessi o la limitazione del trattamento o di opporsi al Trattamento (ex artt. 15, 16, 17,
        18, 19, 21 Reg. UE 279/2016);
      </li>
      <li>
        ii) revocare il consenso in qualsiasi momento senza pregiudicare la liceità del trattamento basata sul consenso
        prestato prima della revoca (ex art. 8, paragrafo 3, del GDPR).
      </li>
    </ol>
    <p>
      I suoi Dati Personali non verranno diffusi e non verranno trasferiti a Paesi terzi o organizzazioni
      internazionali.
    </p>
    <p>
      La informiamo che non esiste un processo decisionale automatizzato, compresa la profilazione di cui all’art. 22,
      paragrafi 1 e 4, del GDPR.
    </p>
    <p>
      EGG è tenuta ad agevolare l’esercizio dei suoi diritti e, in particolare, a fornire un riscontro alla sua
      comunicazione scritta senza ingiustificato ritardo e comunque entro 30 giorni dal ricevimento della medesima.
    </p>
    <h2>10. Reclamo</h2>
    <p>
      Ove ritenga che il trattamento dei suoi Dati Personali violi il GDPR, lei ha diritto di proporre reclamo
      all’Autorità Garante per la Protezione dei Dati Personali.
    </p>
  </div>
)

export default PrivacyDisclaimer
