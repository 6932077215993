import React from 'react'
import { FormikHelpers, useFormik } from 'formik'
import * as yup from 'yup'
import { NewHaemoActivation } from '../../types'
import { Container, FormSection, genOptions, fieldErrors, FormErrors } from './form-helpers'
import FormIntroduction from './form-introduction'
import SectionTitle from './section-title'
import Label from './label'
import Input from './input'
import Checkbox from './checkbox'
import ErrorMessage from './error-message'
import Textarea from './textarea'
import Radio from './radio'
import SubmitButton from './submit-button'
import Field from './field'
import ConsentSection from './consent-section'
import CheckboxGroup from './checkbox-group'
import RequestFollowupOnPatientEnrollment from './request-followup-on-patient-enrollment'
import PatientLocalityField from './patient-locality-field'

type FormValues = NewHaemoActivation

interface Props {
  onSubmit: (activation: NewHaemoActivation, helpers: FormikHelpers<NewHaemoActivation>) => Promise<void>
  pspName: string
}

const PspHaemoForm: React.FC<Props> = ({ onSubmit, pspName }) => {
  const handleSubmit = React.useCallback(
    async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      await onSubmit(values, helpers)
    },
    [onSubmit]
  )

  const formik = useFormik<FormValues>({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
  })

  return (
    <Container>
      <FormIntroduction />
      <form onSubmit={formik.handleSubmit}>
        <FormSection>
          <SectionTitle>Servizio di training</SectionTitle>
          <Checkbox
            {...formik.getFieldProps('trainingService')}
            label="Attivare il servizio di training domiciliare per auto somministrazione endovenosa di fattori della coagulazione in emofilia"
            error={fieldErrors('trainingService', formik)}
          />
          {formik.values.trainingService && (
            <React.Fragment>
              <Radio
                {...formik.getFieldProps('probabilityOfTrainingSuccess')}
                label="Propensione del Paziente (o del suo Caregiver) a diventare autonomo nell’auto somministrazione endovenosa di fattori della coagulazione"
                options={probabilityOfTrainingSuccessOptions}
                error={fieldErrors('probabilityOfTrainingSuccess', formik)}
                required
              />
              <Field>
                <Label text="Indicare eventuali fattori che potrebbero influire positivamente o negativamente sulla capacità del Paziente o del caregiver di raggiungere un buon livello di autonomia entro 3 mesi dall’inizio" />
                <Textarea {...formik.getFieldProps('trainingNote')} />
                <ErrorMessage error={fieldErrors('trainingNote', formik)} />
              </Field>
              <CheckboxGroup>
                <Label text="Confronto con il Medico di Servizio" />
                <Checkbox
                  {...formik.getFieldProps('requestForContactWithServicePhysicianForTraining')}
                  label="Si richiede di poter avere un confronto diretto con il Medico di Servizio di Egg"
                  error={fieldErrors('requestForContactWithServicePhysicianForTraining', formik)}
                />
              </CheckboxGroup>
              {formik.values.requestForContactWithServicePhysicianForTraining && (
                <Radio
                  {...formik.getFieldProps('typeOfContactWithServicePhysicianForTraining')}
                  label="Indicare la modalità di contatto con il Medico di Servizio di Egg"
                  options={typeOfContactWithServicePhysicianOptions}
                  error={fieldErrors('typeOfContactWithServicePhysicianForTraining', formik)}
                  required
                />
              )}
              <CheckboxGroup>
                <Label text="Feedback dopo la prima attività domiciliare" />
                <Checkbox
                  {...formik.getFieldProps('requestForAPhoneContactAfterFirstTraining')}
                  label="Desidero essere contattato telefonicamente dopo la prima attività al domicilio del Paziente per ricevere un feedback dall’Infermiere"
                  error={fieldErrors('requestForAPhoneContactAfterFirstTraining', formik)}
                />
              </CheckboxGroup>
            </React.Fragment>
          )}
        </FormSection>
        <FormSection>
          <SectionTitle>Servizio di somministrazione</SectionTitle>
          <Checkbox
            {...formik.getFieldProps('infusionService')}
            label="Attivare il servizio di somministrazione endovenosa domicilaire di fattori della coagulazione in Emofilia"
            error={fieldErrors('infusionService', formik)}
          />
          {formik.values.infusionService && (
            <React.Fragment>
              <CheckboxGroup css={{ marginTop: 32 }}>
                <Label text="Confronto con il Medico di Servizio" />
                <Checkbox
                  {...formik.getFieldProps('requestForContactWithServicePhysicianForInfusion')}
                  label="Si richiede di poter avere un confronto diretto con il Medico di Servizio di Egg"
                  error={fieldErrors('requestForContactWithServicePhysicianForInfusion', formik)}
                />
              </CheckboxGroup>
              {formik.values.requestForContactWithServicePhysicianForInfusion && (
                <Radio
                  {...formik.getFieldProps('typeOfContactWithServicePhysicianForInfusion')}
                  label="Indicare la modalità di contatto con il Medico di Servizio di Egg"
                  options={typeOfContactWithServicePhysicianOptions}
                  error={fieldErrors('typeOfContactWithServicePhysicianForInfusion', formik)}
                  required
                />
              )}
              <CheckboxGroup>
                <Label text="Feedback dopo la prima attività domiciliare" />
                <Checkbox
                  {...formik.getFieldProps('requestForAPhoneContactAfterFirstInfusion')}
                  label="Desidero essere contattato telefonicamente dopo la prima attività al domicilio del Paziente per ricevere un feedback dall’Infermiere"
                  error={fieldErrors('requestForAPhoneContactAfterFirstInfusion', formik)}
                />
              </CheckboxGroup>
            </React.Fragment>
          )}
        </FormSection>
        <FormSection>
          <SectionTitle>Servizio di supporto fisioterapico</SectionTitle>
          <Checkbox
            {...formik.getFieldProps('physiotherapyService')}
            label="Attivare il servizio di supporto fisioterapico a domicilio"
            error={fieldErrors('physiotherapyService', formik)}
          />
          {formik.values.physiotherapyService && (
            <React.Fragment>
              <Radio
                {...formik.getFieldProps('presenceOfArthropathy')}
                label="Presenza di artropatia"
                options={yesNoOptions}
                error={fieldErrors('presenceOfArthropathy', formik)}
                required
              />
              <CheckboxGroup>
                <Label text="Indicare articolazioni bersaglio" />
                <Checkbox
                  {...formik.getFieldProps('targetJoinsAnkle')}
                  label="Caviglia"
                  error={fieldErrors('targetJoinsAnkle', formik)}
                />
                <Checkbox
                  {...formik.getFieldProps('targetJoinsKnee')}
                  label="Ginocchio"
                  error={fieldErrors('targetJoinsKnee', formik)}
                />
                <Checkbox
                  {...formik.getFieldProps('targetJoinsElbow')}
                  label="Gomito"
                  error={fieldErrors('targetJoinsElbow', formik)}
                />
                <Checkbox
                  {...formik.getFieldProps('targetJoinsOther')}
                  label="Altro"
                  error={fieldErrors('targetJoinsOther', formik)}
                />
              </CheckboxGroup>
              {formik.values.targetJoinsOther && (
                <Field>
                  <Label text="Specificare le altre articolazioni bersaglio" required />
                  <Input {...formik.getFieldProps('targetJoinsOtherSpecify')} />
                  <ErrorMessage error={fieldErrors('targetJoinsOtherSpecify', formik)} />
                </Field>
              )}
              <Field>
                <Label text="Fornire una breve relazione indicando la presenza di eventuali emartri, sanguinamenti o eventi che possono influire nello svolgimento dell’attività fisioterapica sul Paziente" />
                <Textarea {...formik.getFieldProps('physiotherapyInformations')} />
                <ErrorMessage error={fieldErrors('physiotherapyInformations', formik)} />
              </Field>
              {formik.values.physiotherapyInformations !== '' && (
                <Checkbox
                  {...formik.getFieldProps('physiotherapyInformationsPreTherapy')}
                  label='Le indicazioni sanitarie fornite sono antecedenti e non correlate all’uso del farmaco indicato nella sezione "Piano Terapeutico"'
                  error={fieldErrors('physiotherapyInformationsPreTherapy', formik)}
                  css={{ marginTop: -24 }}
                />
              )}
              <CheckboxGroup css={{ marginTop: 32 }}>
                <Label text="Tipo di fisioterapia richiesta" />
                <Checkbox
                  {...formik.getFieldProps('typeOfRequestedPhysiotherapyPostSurgery')}
                  label="Post-Chirurgica"
                  error={fieldErrors('typeOfRequestedPhysiotherapyPostSurgery', formik)}
                />
                <Checkbox
                  {...formik.getFieldProps('typeOfRequestedPhysiotherapyMaintenance')}
                  label="Mantenimento"
                  error={fieldErrors('typeOfRequestedPhysiotherapyMaintenance', formik)}
                />
                <Checkbox
                  {...formik.getFieldProps('typeOfRequestedPhysiotherapyProprioceptive')}
                  label="Propriocettiva"
                  error={fieldErrors('typeOfRequestedPhysiotherapyProprioceptive', formik)}
                />
              </CheckboxGroup>
              <CheckboxGroup>
                <Label text="Articolazioni da trattare" />
                <Checkbox
                  {...formik.getFieldProps('jointsToTargetAnkle')}
                  label="Caviglia"
                  error={fieldErrors('jointsToTargetAnkle', formik)}
                />
                <Checkbox
                  {...formik.getFieldProps('jointsToTargetKnee')}
                  label="Ginocchio"
                  error={fieldErrors('jointsToTargetKnee', formik)}
                />
                <Checkbox
                  {...formik.getFieldProps('jointsToTargetElbow')}
                  label="Gomito"
                  error={fieldErrors('jointsToTargetElbow', formik)}
                />
                <Checkbox
                  {...formik.getFieldProps('jointsToTargetOther')}
                  label="Altro"
                  error={fieldErrors('jointsToTargetOther', formik)}
                />
              </CheckboxGroup>
              {formik.values.jointsToTargetOther && (
                <Field>
                  <Label text="Specificare le altre articolazioni da trattare" required />
                  <Input {...formik.getFieldProps('jointsToTargetOtherSpecify')} />
                  <ErrorMessage error={fieldErrors('jointsToTargetOtherSpecify', formik)} />
                </Field>
              )}
              <Field>
                <Label text="Scopo ed obiettivi dell'attività fisioterapica richiesta" />
                <Textarea {...formik.getFieldProps('physiotherapyGoals')} />
                <ErrorMessage error={fieldErrors('physiotherapyGoals', formik)} />
              </Field>
              <Field>
                <Label text="Durata del trattamento (in mesi, massimo 6 mesi)" required />
                <Input {...formik.getFieldProps('physiotherapyDuration')} />
                <ErrorMessage error={fieldErrors('physiotherapyDuration', formik)} />
              </Field>
              <Field>
                <Label text="Frequenza di accessi settimanali (da 1 a 5)" required />
                <Input {...formik.getFieldProps('visitsFrequency')} />
                <ErrorMessage error={fieldErrors('visitsFrequency', formik)} />
              </Field>
            </React.Fragment>
          )}
        </FormSection>
        <FormSection>
          <SectionTitle>Servizio di prelievo ematico</SectionTitle>
          <Checkbox
            {...formik.getFieldProps('bloodWithdrawalService')}
            label="Attivare il servizio di prelievo ematico per il monitoraggio della terapia"
            error={fieldErrors('bloodWithdrawalService', formik)}
          />
          {formik.values.bloodWithdrawalService && (
            <React.Fragment>
              <Field css={{ marginTop: 32 }}>
                <Label text="Data e ora del 1° prelievo" required />
                <Input {...formik.getFieldProps('firstWithdrawalDateAndTime')} type="datetime-local" />
                <ErrorMessage error={fieldErrors('firstWithdrawalDateAndTime', formik)} />
              </Field>
              <Field>
                <Label text="Data e ora del 2° prelievo" />
                <Input {...formik.getFieldProps('secondWithdrawalDateAndTime')} type="datetime-local" />
                <ErrorMessage error={fieldErrors('secondWithdrawalDateAndTime', formik)} />
              </Field>
              <Field>
                <Label text="Data e ora del 3° prelievo" />
                <Input {...formik.getFieldProps('thirdWithdrawalDateAndTime')} type="datetime-local" />
                <ErrorMessage error={fieldErrors('thirdWithdrawalDateAndTime', formik)} />
              </Field>
              <Field>
                <Label text="Data e ora del 4° prelievo" />
                <Input {...formik.getFieldProps('fourthWithdrawalDateAndTime')} type="datetime-local" />
                <ErrorMessage error={fieldErrors('fourthWithdrawalDateAndTime', formik)} />
              </Field>
              <Field>
                <Label text="Data e ora del 5° prelievo" />
                <Input {...formik.getFieldProps('fifthWithdrawalDateAndTime')} type="datetime-local" />
                <ErrorMessage error={fieldErrors('fifthWithdrawalDateAndTime', formik)} />
              </Field>
              <Field>
                <Label text="Indicare envetuali indicazioni aggiuntive (incluso prelievi oltre i 5)" />
                <Textarea {...formik.getFieldProps('withdrawalSchemeNote')} />
                <ErrorMessage error={fieldErrors('withdrawalSchemeNote', formik)} />
              </Field>
              <Field>
                <Label
                  text="Indicare il tempo massimo che può trascorrere dal prelievo alla consegna del campione ematico al Laboratorio"
                  required
                />
                <Input {...formik.getFieldProps('maxTimeBetweenWithdrawalAndDelivery')} />
                <ErrorMessage error={fieldErrors('maxTimeBetweenWithdrawalAndDelivery', formik)} />
              </Field>
              <Radio
                {...formik.getFieldProps('labHasBeenInformed')}
                label="Il Laboratorio è stato informato dell'attivazione del Servizio per il Paziente?"
                options={yesNoOptions}
                error={fieldErrors('labHasBeenInformed', formik)}
                required
              />
              <Field>
                <Label text="Referente del Laboratorio" />
                <Input {...formik.getFieldProps('labContact')} />
                <ErrorMessage error={fieldErrors('labContact', formik)} />
              </Field>
              <Field>
                <Label text="Recapito telefonico del referente del Laboratorio" />
                <Input {...formik.getFieldProps('labContactPhone')} />
                <ErrorMessage error={fieldErrors('labContactPhone', formik)} />
              </Field>
              <Field>
                <Label text="Note" />
                <Textarea {...formik.getFieldProps('withdrawalNote')} />
                <ErrorMessage error={fieldErrors('withdrawalNote', formik)} />
              </Field>
            </React.Fragment>
          )}
        </FormSection>
        <FormSection>
          <SectionTitle css={{ marginTop: 32 }}>Consegna domiciliare del farmaco</SectionTitle>
          <Checkbox
            {...formik.getFieldProps('drugDeliveryService')}
            label="Attivare il servizio di consegna domiciliare del farmaco"
            error={fieldErrors('drugDeliveryService', formik)}
          />
          {formik.values.drugDeliveryService && (
            <React.Fragment>
              <Radio
                label="Farmacia per il ritiro"
                {...formik.getFieldProps('pharmacyOptions')}
                options={pharmacyOptions}
                error={fieldErrors('pharmacyOptions', formik)}
                required
              />
              {formik.values.pharmacyOptions === 'Gli estremi della Farmacia sono i seguenti' && (
                <Field css={{ marginTop: 16 }}>
                  <Label text="Indicare gli estremi della Farmacia" required />
                  <Textarea
                    {...formik.getFieldProps('pharmacyInformations')}
                    placeholder="Nome, contatto telefonico, email, indirizzo, eventuale persona di riferimento, ..."
                  />
                  <ErrorMessage error={fieldErrors('pharmacyInformations', formik)} />
                </Field>
              )}
            </React.Fragment>
          )}
        </FormSection>
        <FormSection>
          <SectionTitle>Informazioni sul piano terapeutico</SectionTitle>
          <Radio
            {...formik.getFieldProps('drugName')}
            label="Indicare il farmaco con cui il Paziente è in terapia"
            options={drugOptions}
            error={fieldErrors('drugName', formik)}
            required
          />
          {(formik.values.trainingService || formik.values.infusionService || formik.values.physiotherapyService) && (
            <React.Fragment>
              <Field>
                <Label text="Numero di somministrazioni settimanali" required />
                <Input {...formik.getFieldProps('infusionFrequency')} />
                <ErrorMessage error={fieldErrors('infusionFrequency', formik)} />
              </Field>
              <Field>
                <Label text="Dosaggio" required />
                <Input {...formik.getFieldProps('dose')} />
                <ErrorMessage error={fieldErrors('dose', formik)} />
              </Field>
            </React.Fragment>
          )}
          {(formik.values.trainingService ||
            formik.values.infusionService ||
            formik.values.physiotherapyService ||
            formik.values.bloodWithdrawalService) && (
            <React.Fragment>
              <Radio
                {...formik.getFieldProps('infusionType')}
                label="Modalità di infusione"
                options={infusionTypeOptions}
                error={fieldErrors('infusionType', formik)}
                required
              />
              {formik.values.infusionType === 'Altro' && (
                <Field>
                  <Label text="Specificare modalità di infusione (altro)" required />
                  <Input {...formik.getFieldProps('infusionTypeOther')} />
                  <ErrorMessage error={fieldErrors('infusionTypeOther', formik)} />
                </Field>
              )}
            </React.Fragment>
          )}
          {(formik.values.trainingService || formik.values.infusionService) && (
            <Field>
              <Label text="Velocità di infusione" />
              <Input {...formik.getFieldProps('infusionSpeed')} />
              <ErrorMessage error={fieldErrors('infusionSpeed', formik)} />
            </Field>
          )}
          {(formik.values.trainingService ||
            formik.values.infusionService ||
            formik.values.physiotherapyService ||
            formik.values.bloodWithdrawalService) && (
            <React.Fragment>
              <Field>
                <Label text="Indicazioni sanitarie" />
                <Textarea {...formik.getFieldProps('sanitaryInformations')} />
                <ErrorMessage error={fieldErrors('sanitaryInformations', formik)} />
              </Field>
              {formik.values.sanitaryInformations !== '' && (
                <Checkbox
                  {...formik.getFieldProps('sanitaryInformationsPreTherapy')}
                  label={`Le indicazioni sanitarie fornite sono antecedenti e non correlate all'uso del farmaco indicato nella sezione "Piano Terapeutico"`}
                  error={fieldErrors('sanitaryInformationsPreTherapy', formik)}
                  css={{ marginTop: -24 }}
                />
              )}
            </React.Fragment>
          )}
        </FormSection>

        <PatientLocalityField
          formik={formik}
          visible={
            formik.values.trainingService ||
            formik.values.infusionService ||
            formik.values.physiotherapyService ||
            formik.values.bloodWithdrawalService
          }
        />
        <RequestFollowupOnPatientEnrollment formik={formik} />
        <ConsentSection
          {...formik.getFieldProps('physicianConfirmation')}
          pspName={pspName}
          drugName={formik.values.drugName || 'Advate o Feiba o Adynovi'}
          error={fieldErrors('physicianConfirmation', formik)}
        />
        <SubmitButton formik={formik} />
        <FormErrors formik={formik} />
      </form>
    </Container>
  )
}

const initialValues: FormValues = {
  // For all
  drugName: '',
  patientLocality: '',

  // Training Service
  trainingService: false,
  requestForContactWithServicePhysicianForTraining: false,
  typeOfContactWithServicePhysicianForTraining: '',
  probabilityOfTrainingSuccess: '',
  trainingNote: '',
  requestForAPhoneContactAfterFirstTraining: false,

  // InfusionService
  infusionService: false,
  requestForContactWithServicePhysicianForInfusion: false,
  typeOfContactWithServicePhysicianForInfusion: '',
  requestForAPhoneContactAfterFirstInfusion: false,

  // Physiotherapy
  physiotherapyService: false,
  presenceOfArthropathy: '',
  targetJoinsAnkle: false,
  targetJoinsKnee: false,
  targetJoinsElbow: false,
  targetJoinsOther: false,
  targetJoinsOtherSpecify: '',
  physiotherapyInformations: '',
  physiotherapyInformationsPreTherapy: false,
  typeOfRequestedPhysiotherapyPostSurgery: false,
  typeOfRequestedPhysiotherapyMaintenance: false,
  typeOfRequestedPhysiotherapyProprioceptive: false,
  jointsToTargetAnkle: false,
  jointsToTargetKnee: false,
  jointsToTargetElbow: false,
  jointsToTargetOther: false,
  jointsToTargetOtherSpecify: '',
  physiotherapyGoals: '',
  physiotherapyDuration: '',
  visitsFrequency: '',

  // Blood Withdrawal Service
  bloodWithdrawalService: false,
  firstWithdrawalDateAndTime: '',
  secondWithdrawalDateAndTime: '',
  thirdWithdrawalDateAndTime: '',
  fourthWithdrawalDateAndTime: '',
  fifthWithdrawalDateAndTime: '',
  withdrawalSchemeNote: '',
  maxTimeBetweenWithdrawalAndDelivery: '',
  labHasBeenInformed: '',
  labContact: '',
  labContactPhone: '',
  withdrawalNote: '',

  // Therapy required for training, infusion and physio
  infusionFrequency: '',
  dose: '',
  infusionType: '', // Required for blood samples
  infusionTypeOther: '', // Required for blood samples
  infusionSpeed: '', // not required for physio

  sanitaryInformations: '',
  sanitaryInformationsPreTherapy: false,

  // Drug delivery service
  drugDeliveryService: false,
  pharmacyOptions: '',
  pharmacyInformations: '',

  // Follow-up on Patient erollment
  requestFollowupOnPatientEnrollment: false,

  // Physician confirmation
  physicianConfirmation: false,
}

const pharmacyValues = [
  'Il Paziente conosce gli estremi della Farmacia presso cui effettuare il ritiro della terapia',
  'Gli estremi della Farmacia sono i seguenti',
]
const pharmacyOptions = genOptions(pharmacyValues)

const drugValues = ['Advate', 'Feiba', 'Adynovi']
const drugOptions = genOptions(drugValues)

const typeOfContactWithServicePhysicianValues = ['Incontro al Centro', 'Contatto telefonico']
const typeOfContactWithServicePhysicianOptions = genOptions(typeOfContactWithServicePhysicianValues)

const probabilityOfTrainingSuccessValues = ['Nessuna', 'Scarsa', 'Discreta', 'Buona']
const probabilityOfTrainingSuccessOptions = genOptions(probabilityOfTrainingSuccessValues)

const yesNoValues = ['Si', 'No']
const yesNoOptions = genOptions(yesNoValues)

const infusionTypeValues = ['Endovena', 'Altro']
const infusionTypeOptions = genOptions(infusionTypeValues)

const validationSchema = yup.object({
  drugName: yup.string().required('Campo obbligatorio').oneOf(drugValues, 'Inserire il valore corretto'),

  // Training Service
  trainingService: yup
    .boolean()
    .when(['infusionService', 'physiotherapyService', 'bloodWithdrawalService', 'drugDeliveryService'], {
      is: (iS: boolean, pS: boolean, bwS: boolean, ddS: boolean) => !iS && !pS && !bwS && !ddS,
      then: yup.boolean().oneOf([true], "E' necessario attivare almeno un servizio"),
      otherwise: yup.boolean(),
    })
    .when('infusionService', {
      is: true,
      then: yup
        .boolean()
        .oneOf([false], 'Non è possibile attivare il servizio di training insieme a quello di somministrazione'),
    }),
  requestForContactWithServicePhysicianForTraining: yup.boolean(),
  typeOfContactWithServicePhysicianForTraining: yup.string().when('requestForContactWithServicePhysicianForTraining', {
    is: true,
    then: yup
      .string()
      .required('Campo obbligatorio')
      .oneOf(typeOfContactWithServicePhysicianValues, 'Inserire il valore corretto'),
    otherwise: yup.string().notRequired(),
  }),
  probabilityOfTrainingSuccess: yup.string().when('trainingService', {
    is: true,
    then: yup
      .string()
      .required('Campo obbligatorio')
      .oneOf(probabilityOfTrainingSuccessValues, 'Inserire il valore corretto'),
    otherwise: yup.string().notRequired(),
  }),
  trainingNote: yup.string().notRequired(),
  requestForAPhoneContactAfterFirstTraining: yup.boolean(),

  // Infusion Service
  infusionService: yup.boolean(),
  requestForContactWithServicePhysicianForInfusion: yup.boolean(),
  typeOfContactWithServicePhysicianForInfusion: yup.string().when('requestForContactWithServicePhysicianForInfusion', {
    is: true,
    then: yup
      .string()
      .required('Campo obbligatorio')
      .oneOf(typeOfContactWithServicePhysicianValues, 'Inserire il valore corretto'),
    otherwise: yup.string().notRequired(),
  }),
  requestForAPhoneContactAfterFirstInfusion: yup.boolean(),

  // Physiotherapy
  physiotherapyService: yup.boolean(),
  presenceOfArthropathy: yup.string().when('physiotherapyService', {
    is: true,
    then: yup.string().required('Campo obbligatorio').oneOf(yesNoValues, 'Inserire il valore corretto'),
    otherwise: yup.string().notRequired(),
  }),
  targetJoinsAnkle: yup.boolean(),
  targetJoinsKnee: yup.boolean(),
  targetJoinsElbow: yup.boolean(),
  targetJoinsOther: yup.boolean(),
  targetJoinsOtherSpecify: yup.string().when('targetJoinsOther', {
    is: true,
    then: yup.string().required('Campo obbligatorio'),
    otherwise: yup.string().notRequired(),
  }),
  physiotherapyInformations: yup.string().notRequired(),
  physiotherapyInformationsPreTherapy: yup.boolean(),
  typeOfRequestedPhysiotherapyPostSurgery: yup.boolean(),
  typeOfRequestedPhysiotherapyMaintenance: yup.boolean(),
  typeOfRequestedPhysiotherapyProprioceptive: yup.boolean(),
  jointsToTargetAnkle: yup.boolean(),
  jointsToTargetKnee: yup.boolean(),
  jointsToTargetElbow: yup.boolean(),
  jointsToTargetOther: yup.boolean(),
  jointsToTargetOtherSpecify: yup.string().when('jointsToTargetOther', {
    is: true,
    then: yup.string().required('Campo obbligatorio'),
    otherwise: yup.string().notRequired(),
  }),
  physiotherapyGoals: yup.string().notRequired(),
  physiotherapyDuration: yup.string().when('physiotherapyService', {
    is: true,
    then: yup.string().required('Campo obbligatorio'),
    otherwise: yup.string().notRequired(),
  }),
  visitsFrequency: yup.string().when('physiotherapyService', {
    is: true,
    then: yup.string().required('Campo obbligatorio'),
    otherwise: yup.string().notRequired(),
  }),

  // Blood Withdrawal Service
  bloodWithdrawalService: yup.boolean(),
  firstWithdrawalDateAndTime: yup.string().when('bloodWithdrawalService', {
    is: true,
    then: yup.string().required('Campo obbligatorio'),
    otherwise: yup.string().notRequired(),
  }),
  secondWithdrawalDateAndTime: yup.string().notRequired(),
  thirdWithdrawalDateAndTime: yup.string().notRequired(),
  fourthWithdrawalDateAndTime: yup.string().notRequired(),
  fifthWithdrawalDateAndTime: yup.string().notRequired(),
  withdrawalSchemeNote: yup.string().notRequired(),
  maxTimeBetweenWithdrawalAndDelivery: yup.string().when('bloodWithdrawalService', {
    is: true,
    then: yup.string().required('Campo obbligatorio'),
    otherwise: yup.string().notRequired(),
  }),
  labHasBeenInformed: yup.string().when('bloodWithdrawalService', {
    is: true,
    then: yup.string().required('Campo obbligatorio').oneOf(yesNoValues, 'Inserire il valore corretto'),
    otherwise: yup.string().notRequired(),
  }),
  labContact: yup.string().notRequired(),
  labContactPhone: yup.string().notRequired(),
  withdrawalNote: yup.string().notRequired(),

  // Therapy required for training, infusion and physio
  infusionFrequency: yup.string().when(['trainingService', 'infusionService', 'physiotherapyService'], {
    is: (tS: boolean, iS: boolean, pS: boolean) => tS || iS || pS,
    then: yup.string().required('Campo obbligatorio'),
    otherwise: yup.string().notRequired(),
  }),
  dose: yup.string().when(['trainingService', 'infusionService', 'physiotherapyService'], {
    is: (tS: boolean, iS: boolean, pS: boolean) => tS || iS || pS,
    then: yup.string().required('Campo obbligatorio'),
    otherwise: yup.string().notRequired(),
  }),
  infusionType: yup
    .string()
    .when(['trainingService', 'infusionService', 'physiotherapyService', 'bloodWithdrawalService'], {
      is: (tS: boolean, iS: boolean, pS: boolean, bwS: boolean) => tS || iS || pS || bwS,
      then: yup.string().oneOf(infusionTypeValues, 'Inserire il valore corretto'),
      otherwise: yup.string().notRequired(),
    }), // Required for blood samples
  infusionTypeOther: yup.string().when('infusionType', {
    is: 'Altro',
    then: yup.string().required('Campo obbligatorio'),
    otherwise: yup.string().notRequired(),
  }),
  infusionSpeed: yup.string().notRequired(),
  sanitaryInformations: yup.string().notRequired(),
  sanitaryInformationsPreTherapy: yup.boolean(),

  // Drug delivery service
  drugDeliveryService: yup.boolean(),
  pharmacyOptions: yup.string().when('drugDeliveryService', {
    is: true,
    then: yup.string().required('Campo obbligatorio').oneOf(pharmacyValues, 'Inserire il valore corretto'),
    otherwise: yup.string().notRequired(),
  }),
  pharmacyInformations: yup.string().when('pharmacyOptions', {
    is: 'Gli estremi della Farmacia sono i seguenti',
    then: yup.string().required('Campo obbligatorio'),
    otherwise: yup.string().notRequired(),
  }),

  requestFollowupOnPatientEnrollment: yup.boolean(),

  patientLocality: yup
    .string()
    .when(['trainingService', 'infusionService', 'physiotherapyService', 'bloodWithdrawalService'], {
      is: (tS: boolean, iS: boolean, pS: boolean, bWS: boolean) => tS || iS || pS || bWS,
      then: yup.string().required('Campo obbligatorio'),
      otherwise: yup.string().notRequired(),
    }),

  physicianConfirmation: yup
    .boolean()
    .oneOf([true], "Senza conferma non è possibile procedere con l'invio della richiesta di attivazione dei servizi"),
})

export default PspHaemoForm
