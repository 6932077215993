import React from 'react'
import { FormikHelpers, useFormik } from 'formik'
import * as yup from 'yup'
import { NewIpoActivation } from '../../types'
import { Container, FormSection, fieldErrors, FormErrors, genOptions } from './form-helpers'
import FormIntroduction from './form-introduction'
import SectionTitle from './section-title'
import Checkbox from './checkbox'
import Radio from './radio'
import Field from './field'
import Label from './label'
import Textarea from './textarea'
import ErrorMessage from './error-message'
import ConsentSection from './consent-section'
import SubmitButton from './submit-button'
import Input from './input'
import RequestFollowupOnPatientEnrollment from './request-followup-on-patient-enrollment'
import PatientLocalityField from './patient-locality-field'

type FormValues = NewIpoActivation

interface Props {
  onSubmit: (activation: NewIpoActivation, helpers: FormikHelpers<NewIpoActivation>) => Promise<void>
  pspName: string
}

const PspIpoForm: React.FC<Props> = ({ onSubmit, pspName }) => {
  const handleSubmit = React.useCallback(
    async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      await onSubmit(values, helpers)
      helpers.setSubmitting(false)
    },
    [onSubmit]
  )

  const formik = useFormik<FormValues>({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
  })

  return (
    <Container>
      <FormIntroduction />
      <form onSubmit={formik.handleSubmit}>
        <FormSection>
          <SectionTitle>Servizio di training e monitoraggio</SectionTitle>
          <Checkbox
            {...formik.getFieldProps('ipoparaCareService')}
            label="Attivare il servizio di training iniziale sulla ricostituzione e somministrazione della terapia ed il monitoraggio costante dei parametri clinici tramite infermieri specializzati"
            error={fieldErrors('ipoparaCareService', formik)}
          />
          {formik.values.ipoparaCareService && (
            <React.Fragment>
              <Field>
                <Label text="Dosaggio Natpar (mcg)" required />
                <Input {...formik.getFieldProps('dose')} />
                <ErrorMessage error={fieldErrors('dose', formik)} />
              </Field>
              <Field>
                <Label text="Note sul dosaggio" />
                <Textarea {...formik.getFieldProps('doseNote')} />
                <ErrorMessage error={fieldErrors('doseNote', formik)} />
              </Field>
              <Field>
                <Label text="Valore della calcemia al momento della presa in carico (mg/dl)" />
                <Input {...formik.getFieldProps('calcemiaAtTakingCharge')} />
                <ErrorMessage error={fieldErrors('calcemiaAtTakingCharge', formik)} />
              </Field>
              <Field>
                <Label text="Indicare eventuali informazioni relative al quadro clinico del Paziente che devono essere prese in considerazione durante il training iniziale sulla somministrazione della terapia, nelle visite domiciliari o nelle telefonate di controllo" />
                <Textarea {...formik.getFieldProps('sanitaryInformations')} />
                <ErrorMessage error={fieldErrors('sanitaryInformations', formik)} />
              </Field>
              {formik.values.sanitaryInformations !== '' && (
                <Checkbox
                  {...formik.getFieldProps('sanitaryInformationsPreTherapy')}
                  label="Le indicazioni sanitarie fornite sono antecedenti e non correlate all’uso di natpar"
                  error={fieldErrors('sanitaryInformationsPreTherapy', formik)}
                  css={{ marginTop: -24, marginBottom: 32 }}
                />
              )}
              <Field>
                <Label text="Indicare eventuali variazioni richieste rispetto al protocollo standard delle visite domiciliari e delle telefonate di controllo" />
                <Textarea {...formik.getFieldProps('monitoringRequests')} />
                <ErrorMessage error={fieldErrors('monitoringRequests', formik)} />
              </Field>
            </React.Fragment>
          )}
        </FormSection>
        <FormSection>
          <SectionTitle css={{ marginTop: 32 }}>Consegna domiciliare del farmaco</SectionTitle>
          <Checkbox
            {...formik.getFieldProps('drugDeliveryService')}
            label="Attivare il servizio di consegna domiciliare del farmaco"
            error={fieldErrors('drugDeliveryService', formik)}
          />
          {formik.values.drugDeliveryService && (
            <React.Fragment>
              <Radio
                label="Farmacia per il ritiro"
                {...formik.getFieldProps('pharmacyOptions')}
                options={pharmacyOptions}
                error={fieldErrors('pharmacyOptions', formik)}
                required
              />
              {formik.values.pharmacyOptions === 'Gli estremi della Farmacia sono i seguenti' && (
                <Field css={{ marginTop: 16 }}>
                  <Label text="Indicare gli estremi della Farmacia" required />
                  <Textarea
                    {...formik.getFieldProps('pharmacyInformations')}
                    placeholder="Nome, contatto telefonico, email, indirizzo, eventuale persona di riferimento, ..."
                  />
                  <ErrorMessage error={fieldErrors('pharmacyInformations', formik)} />
                </Field>
              )}
            </React.Fragment>
          )}
        </FormSection>
        <PatientLocalityField formik={formik} visible={formik.values.ipoparaCareService} />
        <RequestFollowupOnPatientEnrollment formik={formik} />
        <ConsentSection
          {...formik.getFieldProps('physicianConfirmation')}
          pspName={pspName}
          drugName="Natpar"
          error={fieldErrors('physicianConfirmation', formik)}
        />
        <SubmitButton formik={formik} />
        <FormErrors formik={formik} />
      </form>
    </Container>
  )
}

const pharmacyValues = [
  'Il Paziente conosce gli estremi della Farmacia presso cui effettuare il ritiro della terapia',
  'Gli estremi della Farmacia sono i seguenti',
]
const pharmacyOptions = genOptions(pharmacyValues)

const validationSchema = yup.object({
  // Ipopara Care Service
  ipoparaCareService: yup.boolean(),
  dose: yup.string().when('ipoparaCareService', {
    is: true,
    then: yup.string().required('Campo obbligatorio'),
    otherwise: yup.string().notRequired(),
  }),
  doseNote: yup.string().notRequired(),
  calcemiaAtTakingCharge: yup.string().notRequired(),

  sanitaryInformations: yup.string().notRequired(),
  sanitaryInformationsPreTherapy: yup.boolean(),

  monitoringRequests: yup.string().notRequired(),

  // Drug delivery service
  drugDeliveryService: yup.boolean(),
  pharmacyOptions: yup.string().when('drugDeliveryService', {
    is: true,
    then: yup.string().required('Campo obbligatorio').oneOf(pharmacyValues, 'Inserire il valore corretto'),
    otherwise: yup.string().notRequired(),
  }),
  pharmacyInformations: yup.string().when('pharmacyOptions', {
    is: 'Gli estremi della Farmacia sono i seguenti',
    then: yup.string().required('Campo obbligatorio'),
    otherwise: yup.string().notRequired(),
  }),

  requestFollowupOnPatientEnrollment: yup.boolean(),

  patientLocality: yup.string().when('ipoparaCareService', {
    is: true,
    then: yup.string().required('Campo obbligatorio'),
    otherwise: yup.string().notRequired(),
  }),

  // Physician confirmation
  physicianConfirmation: yup
    .boolean()
    .oneOf([true], "Senza conferma non è possibile procedere con l'invio della richiesta di attivazione dei servizi"),
})

const initialValues: FormValues = {
  patientLocality: '',

  // Ipopara Care Service
  ipoparaCareService: false,
  dose: '',
  doseNote: '',
  calcemiaAtTakingCharge: '',

  sanitaryInformations: '',
  sanitaryInformationsPreTherapy: false,

  monitoringRequests: '',

  // Drug delivery service
  drugDeliveryService: false,
  pharmacyOptions: '',
  pharmacyInformations: '',

  // Follow-up on Patient erollment
  requestFollowupOnPatientEnrollment: false,

  // Physician confirmation
  physicianConfirmation: false,
}

export default PspIpoForm
