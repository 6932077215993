import React from 'react'
import styled from '@emotion/styled'
import logoEgg from '../images/logo-egg.svg'
import mediaQueries from '../styles/media-queries'
import headerBackgruoundImageXl from '../images/cover-xl.jpg'
import headerBackgruoundImageLg from '../images/cover-lg.jpg'
import headerBackgruoundImageMd from '../images/cover-md.jpg'
import headerBackgruoundImageSm from '../images/cover-sm.jpg'

type Props = {
  title: string
}

const Header: React.FC<Props> = ({ title }) => {
  return (
    <React.Fragment>
      <HeaderContainer>
        <Logo src={logoEgg} alt="Logo Egg" />
        <Title>{title}</Title>
        <EmptyLogo />
      </HeaderContainer>
      <HeaderSpacer />
    </React.Fragment>
  )
}

const backgroundImg = (imgUrl: string) => `
  linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${imgUrl})
`
const HeaderContainer = styled.div({
  width: '100%',
  height: 60,
  color: 'white',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 20px',
  boxSizing: 'border-box',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 100,
  backgroundImage: backgroundImg(headerBackgruoundImageSm),
  backgroundSize: '',
  backgroundPosition: 'top center',
  [mediaQueries('sm')]: {
    backgroundImage: backgroundImg(headerBackgruoundImageSm),
  },
  [mediaQueries('md')]: {
    padding: '0 30px',
    height: 80,
    backgroundImage: backgroundImg(headerBackgruoundImageMd),
  },
  [mediaQueries('lg')]: {
    backgroundImage: backgroundImg(headerBackgruoundImageLg),
  },
  [mediaQueries('xl')]: {
    backgroundImage: backgroundImg(headerBackgruoundImageXl),
  },
})

const HeaderSpacer = styled.div({
  width: '100%',
  height: 60,
  [mediaQueries('md')]: {
    height: 80,
  },
})

const Logo = styled.img({
  height: 70,
  width: 90,
  [mediaQueries('md')]: {
    height: 80,
    width: 120,
  },
})

const EmptyLogo = styled.div({
  height: 70,
  width: 90,
  display: 'none',
  [mediaQueries('sm')]: {
    display: 'block',
  },
  [mediaQueries('md')]: {
    height: 80,
    width: 120,
  },
})

const Title = styled.h1({
  textTransform: 'uppercase',
  fontSize: 14,
  textAlign: 'left',
  fontFamily: 'Lato',
  padding: '0 20px',
  [mediaQueries('sm')]: {
    textAlign: 'center',
  },
  [mediaQueries('md')]: {
    fontSize: 20,
  },
  [mediaQueries('lg')]: {
    fontSize: 24,
  },
})

export default Header
