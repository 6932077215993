import React from 'react'
import styled from '@emotion/styled'
import logoEgg from '../images/logo-egg.svg'
import wrongCodeImgXl from '../images/wrong-code-xl.jpg'
import wrongCodeImgLg from '../images/wrong-code-lg.jpg'
import wrongCodeImgMd from '../images/wrong-code-md.jpg'
import wrongCodeImgSm from '../images/wrong-code-sm.jpg'
import mediaQueries from '../styles/media-queries'
import errorCodeIcon from '../images/error-code-icon.png'

interface Props {
  message?: string
}
const ErrorPage: React.FC<Props> = ({ message, children }) => {
  return (
    <Wrapper>
      <LogoContainer>
        <CompanyLogo alt="Logo Egg" src={logoEgg} />
      </LogoContainer>
      <BoxContainer>
        <AlertImg alt="Thank You" src={errorCodeIcon} />
        <div css={{ marginTop: 32, fontSize: 22, lineHeight: 1.5, width: '100%', b: { fontWeight: 700 } }}>
          <p css={{ textAlign: 'center', fontWeight: 700 }}>ATTENZIONE:</p>
          <p css={{ textAlign: 'center', marginTop: 32 }}>{!!children ? children : message}</p>
        </div>
      </BoxContainer>
      <div css={{ color: '#fff', width: '100%', justifySelf: 'end', marginBottom: 32, marginTop: 32, display: 'flex' }}>
        <span css={{ marginLeft: 'auto', marginRight: 64 }}>Egg Srl &trade; 2020</span>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div({
  width: '100%',
  minHeight: '100vh',
  backgroundImage: `url(${wrongCodeImgSm})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#fbb600',
  [mediaQueries('sm')]: {
    backgroundImage: `url(${wrongCodeImgSm})`,
  },
  [mediaQueries('md')]: {
    backgroundImage: `url(${wrongCodeImgMd})`,
  },
  [mediaQueries('lg')]: {
    backgroundImage: `url(${wrongCodeImgLg})`,
  },
  [mediaQueries('xl')]: {
    backgroundImage: `url(${wrongCodeImgXl})`,
  },
})

const LogoContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  padding: '0 20px',
  width: '100%',
  boxSizing: 'border-box',
  [mediaQueries('sm')]: {
    padding: '0 20px',
  },
  [mediaQueries('md')]: {
    padding: '0 40px',
  },
  [mediaQueries('lg')]: {
    padding: '0 70px',
  },
  [mediaQueries('xl')]: {
    padding: '0 100px',
  },
})

const CompanyLogo = styled.img({
  width: 120,
  height: 120,
  [mediaQueries('sm')]: {
    width: 150,
    height: 150,
  },
  [mediaQueries('md')]: {
    width: 180,
    height: 180,
  },
  [mediaQueries('lg')]: {
    width: 200,
    height: 200,
  },
  [mediaQueries('xl')]: {
    width: 250,
    height: 250,
  },
})

const BoxContainer = styled.div({
  boxSizing: 'border-box',
  backgroundColor: '#fff',
  opacity: 0.7,
  padding: 16,
  margin: 16,
  maxWidth: 960,
  borderRadius: 20,
  width: '90%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [mediaQueries('md')]: {
    padding: 32,
    margin: 32,
  },
})

const AlertImg = styled.img({
  width: 100,
  [mediaQueries('md')]: {
    width: 150,
  },
  [mediaQueries('lg')]: {
    width: 200,
  },
})

export default ErrorPage
