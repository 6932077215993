import React from 'react'
import { Page, Text, View, Document } from '@react-pdf/renderer'
import { ActivationPsp, NewMICIActivation } from '../../types'
import { styles } from './pdf-form-styles'

interface Props {
  serviceActivation: NewMICIActivation
  serviceActivationId: string
  activationPsp: ActivationPsp
  createdAt: Date
  patientName: string
}

const MiciActivationFormPDF: React.FC<Props> = ({
  serviceActivationId,
  serviceActivation,
  activationPsp,
  createdAt,
  patientName,
}) => (
  <Document>
    <Page style={styles.body}>
      <View>
        <Text style={styles.title}>PROGRAMMA DI SUPPORTO AL PAZIENTE IN {activationPsp.shortName.toUpperCase()}</Text>
        <Text style={styles.undertitle}>Scheda di Attivazione dei Servizi</Text>
        <Text style={styles.text}>
          La presente scheda è stata compilata dal Medico Specialista e trasmessa ad Egg S.r.l. al fine di consentire
          l’attivazione dei Servizi per i Pazienti che aderiscono al {activationPsp.name}.
        </Text>
      </View>
      <View style={{ textAlign: 'center', marginTop: 4, marginBottom: 4 }}>
        <Text style={{ fontWeight: 'bold', fontSize: 16 }}>
          Codice attivazione: <Text>{serviceActivationId}</Text>
        </Text>
        <Text style={{ fontWeight: 'bold', fontSize: 14 }}>
          Paziente: <Text>{patientName}</Text>
        </Text>
        {serviceActivation.patientLocality !== '' && (
          <Text style={{ fontWeight: 'bold', fontSize: 14 }}>
            Comune domicilio Paziente: <Text>{serviceActivation.patientLocality}</Text>
          </Text>
        )}
      </View>
      <View style={styles.section}>
        <Text style={styles.subtitle}>Dati anamnestici</Text>
        <View style={styles.field}>
          <Text style={styles.label}>Patologia: </Text>
          <Text style={styles.value}>{serviceActivation.pathology}</Text>
        </View>
      </View>
      <View style={styles.section}>
        <Text style={styles.subtitle}>
          Servizio di training all'autosomministrazione:{' '}
          {serviceActivation.trainingService ? (
            <Text style={styles.serviceStatusActivated}>attivato</Text>
          ) : (
            <Text style={styles.serviceStatusNotActivated}>non attivato</Text>
          )}
        </Text>
        {serviceActivation.trainingService && (
          <View>
            <View style={styles.field}>
              <Text style={styles.label}>Posologia: </Text>
              <Text style={styles.value}>
                {serviceActivation.posology === 'Altro' ? serviceActivation.posologyOther : serviceActivation.posology}
              </Text>
            </View>
            <View style={styles.field}>
              <Text style={styles.label}>Formulazione: </Text>
              <Text style={styles.value}>{serviceActivation.formulation || '-'}</Text>
            </View>
            <View style={styles.field}>
              <Text style={styles.label}>Data della prima sessione di training: </Text>
              <Text style={styles.value}>
                {serviceActivation.firstSessionDateOption === 'In una data specifica'
                  ? serviceActivation.firstSessionDate
                  : serviceActivation.firstSessionDateOption}
              </Text>
            </View>
            {serviceActivation.administrationTollerance !== '' && (
              <View style={styles.field}>
                <Text style={styles.label}>
                  Indicare, in caso di necessità del paziente, un range di tolleranza per lo spostamento della prima
                  somministrazione (es. +/-2 giorni):{' '}
                </Text>
                <Text style={styles.value}>{serviceActivation.administrationTollerance}</Text>
              </View>
            )}
            <View style={styles.field}>
              <Text style={styles.label}>
                Il Training è di norma effettuato da remoto con connessione audio-video. Si richiede che la formazione
                si svolga invece di persona?{' '}
              </Text>
              <Text style={styles.value}>
                {serviceActivation.inPersonSession === 'Si'
                  ? `Si, il luogo di svolgimento della prima sessione è ${serviceActivation.firstInPersonSessionLocation?.toLowerCase()}`
                  : 'No'}
              </Text>
            </View>
            <View style={styles.field}>
              <Text style={styles.label}>
                Consegnare al Paziente un kit dimostrativo che lo aiuterà ad esercitarsi nella corretta pratica
                dell’autosomministrazione
              </Text>
              <Text style={styles.value}>{serviceActivation.shouldReceiveStarterKit ? 'Si' : 'No'}</Text>
            </View>
          </View>
        )}
      </View>
      <View style={styles.section}>
        <Text style={styles.subtitle}>
          Servizio di counseling psicologico:{' '}
          {serviceActivation.counselingService ? (
            <Text style={styles.serviceStatusActivated}>attivato</Text>
          ) : (
            <Text style={styles.serviceStatusNotActivated}>non attivato</Text>
          )}
        </Text>
        {serviceActivation.counselingService && (
          <View>
            <View style={styles.field}>
              <Text style={styles.label}>
                Desidero partecipare alla prima sessione preliminare tra il Paziente e lo Psicologo per essere coinvolto
                nella definizione degli obiettivi di counseling (la sessione si svolge in video conferenza)?{' '}
              </Text>
              <Text style={styles.value}>{serviceActivation.firstCounselingSessionWithHCP ? 'Si' : 'No'}</Text>
            </View>
            <View style={styles.field}>
              <Text style={styles.label}>
                Al termine del percorso di counseling desidero ricevere una relazione da parte dello Psicologo (l'invio
                di una relazione conclusiva è soggetta al consenso da parte del Paziente)?{' '}
              </Text>
              <Text style={styles.value}>{serviceActivation.counselingHCPReport ? 'Si' : 'No'}</Text>
            </View>
          </View>
        )}
      </View>
      <View style={styles.section}>
        <Text style={styles.subtitle}>
          Servizio di corso mindfulness:{' '}
          {serviceActivation.mindfulnessService ? (
            <Text style={styles.serviceStatusActivated}>attivato</Text>
          ) : (
            <Text style={styles.serviceStatusNotActivated}>non attivato</Text>
          )}
        </Text>
        {serviceActivation.mindfulnessService && (
          <View>
            <View style={styles.field}>
              <Text style={styles.label}>
                Desidero partecipare alla prima sessione preliminare tra il Paziente e lo Psicologo per essere coinvolto
                nella condivisione degli obiettivi del corso (la sessione si svolge in video conferenza){' '}
              </Text>
              <Text style={styles.value}>{serviceActivation.firstMindfulnessSessionWithHCP ? 'Si' : 'No'}</Text>
            </View>
            <View style={styles.field}>
              <Text style={styles.label}>
                Al termine del corso di mindfulness desidero ricevere una relazione da parte dello Psicologo{' '}
              </Text>
              <Text style={styles.value}>{serviceActivation.mindfulnessHCPReport ? 'Si' : 'No'}</Text>
            </View>
          </View>
        )}
      </View>
      <View style={styles.section}>
        <Text style={styles.subtitle}>
          Servizio di supporto nutrizionale:{' '}
          {serviceActivation.nutritionalService ? (
            <Text style={styles.serviceStatusActivated}>attivato</Text>
          ) : (
            <Text style={styles.serviceStatusNotActivated}>non attivato</Text>
          )}
        </Text>
        {serviceActivation.nutritionalService && (
          <View>
            <View style={styles.field}>
              <Text style={styles.label}>
                Desidero partecipare alla prima sessione tra il Paziente e il Nutrizionista per essere coinvolto nella
                definizione degli obiettivi del supporto e potermi confrontare con il Nutrizionista (la sessione si
                svolge in video conferenza){' '}
              </Text>
              <Text style={styles.value}>{serviceActivation.firstNutritionalSessionWithHCP ? 'Si' : 'No'}</Text>
            </View>
            <View style={styles.field}>
              <Text style={styles.label}>
                Desidero essere contattato dal Nutrizionista prima dell’invio delle linee guida al Paziente{' '}
              </Text>
              <Text style={styles.value}>{serviceActivation.requestNutritionistCall ? 'Si' : 'No'}</Text>
            </View>
            <View style={styles.field}>
              <Text style={styles.label}>Desidero ricevere le linee guida che verranno inviate al Paziente? </Text>
              <Text style={styles.value}>{serviceActivation.requestNutritionalGuidelines ? 'Si' : 'No'}</Text>
            </View>
            <View style={styles.field}>
              <Text style={styles.label}>Durata della malattia: </Text>
              <Text style={styles.value}>{serviceActivation.illnessDuration || '-'}</Text>
            </View>
            <View style={styles.field}>
              <Text style={styles.label}>Attività attuale della malattia: </Text>
              <Text style={styles.value}>{serviceActivation.illnessActivity || '-'}</Text>
            </View>
            <View style={styles.field}>
              <Text style={styles.label}>
                Altre eventuali patologie associate, e/o non associate (es. Artrite Reumatoide, Celiachia, Tiroidite di
                Hashimoto, o Altra):{' '}
              </Text>
              <Text style={styles.value}>{serviceActivation.otherPathologies || '-'}</Text>
            </View>
            <View style={styles.field}>
              <Text style={styles.label}>Resezione intestino tenute: </Text>
              <Text style={styles.value}>{serviceActivation.bowelResection || '-'}</Text>
            </View>
            <View style={styles.field}>
              <Text style={styles.label}>Resezione colon: </Text>
              <Text style={styles.value}>{serviceActivation.colonResection || '-'}</Text>
            </View>
            <View style={styles.field}>
              <Text style={styles.label}>Resezione ileo-colica: </Text>
              <Text style={styles.value}>{serviceActivation.ileusResection || '-'}</Text>
            </View>
            <View style={styles.field}>
              <Text style={styles.label}>Stomia: </Text>
              <Text style={styles.value}>{serviceActivation.ostomy || '-'}</Text>
            </View>
            <View style={styles.field}>
              <Text style={styles.label}>Pouch: </Text>
              <Text style={styles.value}>{serviceActivation.pouch || '-'}</Text>
            </View>
            <View style={styles.field}>
              <Text style={styles.label}>Fistola/e perianali: </Text>
              <Text style={styles.value}>{serviceActivation.fistula || '-'}</Text>
            </View>
            <View style={styles.field}>
              <Text style={styles.label}>Calo ponderale (rispetto al peso abituale) negli ultimi tre mesi: </Text>
              <Text style={styles.value}>{serviceActivation.weightLoss || '-'}</Text>
            </View>
            <View style={styles.field}>
              <Text style={styles.label}>Esami di laboratorio: </Text>
              <Text style={styles.value}>
                Per un corretto supporto nutrizionale, è opportuno avere a disposizione i referti degli ultimi esami
                ematici. Se disponibili le chiediamo la gentilezza di allegarne una copia, viceversa chiederemo
                direttamente al Suo Paziente.
              </Text>
            </View>
          </View>
        )}
      </View>
      <View style={styles.section}>
        <Text style={styles.subtitle}>
          Servizio di consegna domiciliare del farmaco:{' '}
          {serviceActivation.drugDeliveryService ? (
            <Text style={styles.serviceStatusActivated}>attivato</Text>
          ) : (
            <Text style={styles.serviceStatusNotActivated}>non attivato</Text>
          )}
        </Text>
        {serviceActivation.drugDeliveryService && (
          <View>
            <View style={styles.field}>
              <Text style={styles.label}>Farmacia per il ritiro: </Text>
              <Text style={styles.value}>
                {serviceActivation.pharmacyOptions === 'Gli estremi della Farmacia sono i seguenti'
                  ? serviceActivation.pharmacyInformations
                  : serviceActivation.pharmacyOptions}
              </Text>
            </View>
          </View>
        )}
      </View>
      <View style={styles.fieldNoSection}>
        <Text style={styles.label}>
          Richiedo di essere informato via email quando il Paziente completerà l'adesione:
        </Text>
        <Text style={styles.value}>{serviceActivation.requestFollowupOnPatientEnrollment ? 'Si' : 'No'}</Text>
      </View>
      <View>
        <Text style={styles.text}>
          Io sottoscritto/a, in qualità di Medico Specialista iscritto al {activationPsp.name}, ricevuta richiesta da
          parte del mio Paziente di attivare i servizi inclusi nel Programma, confermo che il Paziente è idoneo a
          ricevere i servizi qui selezionati secondo quanto previsto dalla Scheda Tecnica di Prodotto ed alle
          indicazioni sopra fornitevi. Sono inoltre consapevole che i servizi sopra indicati possono essere attivati
          solo per i Pazienti che hanno già iniziato la terapia con {`Entyvio SC`} e che tali servizi sono da
          considerarsi come addizionali e non sostitutivi a quelli in capo all'Ente o al SSN.
        </Text>
      </View>
      <View style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
        <Text style={styles.text}>
          Data di compilazione: {createdAt.toLocaleDateString()} {createdAt.toLocaleTimeString()}
        </Text>
      </View>
    </Page>
  </Document>
)

export default MiciActivationFormPDF
