import React from 'react'
import Checkbox from './checkbox'
import Field from './field'
import { fieldErrors } from './form-helpers'
import Label from './label'

const RequestFollowupOnPatientEnrollment: React.FC<{ formik: any }> = ({ formik }) => (
  <div css={{ marginTop: 64, marginBottom: -32 }}>
    <Field>
      <Label text="Notifica dopo l'adesione del Paziente" />
      <Checkbox
        {...formik.getFieldProps('requestFollowupOnPatientEnrollment')}
        label="Richiedo di essere informato via email quando il Paziente completerà l'adesione"
        error={fieldErrors('requestFollowupOnPatientEnrollment', formik)}
      />
      <div css={{ color: '#f00', fontSize: 13, lineHeight: 0.9, marginTop: -6, marginLeft: 36 }}>
        Attenzione: in caso di problemi in fase di adesione del Paziente saremo costretti a contattarla
        indipendentemente dalla scelta qui espressa
      </div>
    </Field>
  </div>
)

export default RequestFollowupOnPatientEnrollment
