import React from 'react'
import { useParams, Routes, Route } from 'react-router-dom'
import { verifyCode } from '../api'
import SubscriptionPage from './subscription-page'
import ErrorPage from './error-page'
import Loading from './loading'
import { Psp } from '../types'
import PrivacyPage from './privacy-page'
import WrongCodeErrorMessage from './wrong-code-error-message'

interface State {
  loading: boolean
  error: boolean
  psp?: Psp
}

type Action =
  | {
      type: 'setPsp'
      psp: Psp
    }
  | {
      type: 'error'
    }

const VerifyCode: React.FC<{}> = () => {
  const [state, dispatch] = React.useReducer(reducer, { loading: true, error: false })
  const { code } = useParams<{ code: string }>()

  React.useEffect(() => {
    if (code) {
      verifyCode(code)
        .then((resp) => {
          dispatch({ type: 'setPsp', psp: { ...resp.data, code } })
        })
        .catch((resp) => {
          dispatch({ type: 'error' })
        })
    }
  }, [code])

  if (!code || state.error) {
    return (
      <ErrorPage>
        <WrongCodeErrorMessage />
      </ErrorPage>
    )
  } else if (state.loading || !state.psp) {
    return <Loading />
  } else {
    return (
      <Routes>
        <Route path="/" element={<SubscriptionPage psp={state.psp} />} />
        <Route path={'privacy'} element={<PrivacyPage />} />
        <Route
          path="/*"
          element={
            <ErrorPage>
              <WrongCodeErrorMessage />
            </ErrorPage>
          }
        />
      </Routes>
    )
  }
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'setPsp':
      return { ...state, loading: false, psp: action.psp }
    case 'error':
      return { ...state, loading: false, error: true }
  }
}

export default VerifyCode
