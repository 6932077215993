/// <reference types="@emotion/react/types/css-prop" />

import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
// @ts-ignore
import smoothscroll from 'smoothscroll-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './app'
import '@emotion/react'
import { BrowserRouter as Router } from 'react-router-dom'

smoothscroll.polyfill()

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)
