import React from 'react'

interface Props {
  subtitle?: string
}

const SectionTitle: React.FC<Props> = ({ children, subtitle }) => {
  return (
    <div
      css={{
        marginBottom: 16,
        borderBottom: '3px solid #fbb600',
        paddingBottom: 8,
      }}
    >
      <h2
        css={{
          color: '#000',
          fontFamily: 'Lato',
          fontSize: 22,
          fontWeight: 400,
          textTransform: 'uppercase',
        }}
      >
        {children}
      </h2>
      {subtitle && (
        <h4
          css={{
            color: '#5e5e5e',
            fontFamily: 'Lato',
            fontSize: 16,
            fontWeight: 400,
            marginTop: 8,
          }}
        >
          {subtitle}
        </h4>
      )}
    </div>
  )
}

export default SectionTitle
