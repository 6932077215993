import { FormikHelpers, useFormik } from 'formik'
import * as yup from 'yup'
import React from 'react'
import Field from './field'
import Label from './label'
import { fieldErrors } from './form-helpers'
import ErrorMessage from './error-message'
import Input from './input'
import {
  ActivationPsp,
  NewHaemoActivation,
  NewImmunoActivation,
  NewIpoActivation,
  NewMICIActivation,
  NewSBSActivation,
  ServiceActivation,
} from '../../types'
import { PDFDownloadLink } from '@react-pdf/renderer'
import MiciActivationFormPDF from './mici-activation-form-pdf'
import HaemoActivationFormPDF from './haemo-activation-form-pdf'
import ImmunoActivationFormPDF from './immuno-activation-form-pdf'
import SBSActivationFormPDF from './sbs-activation-form-pdf'
import IpoActivationFormPDF from './ipo-activation-form-pdf'

interface FormValues {
  name: string
}

interface Props {
  activationPsp: ActivationPsp
  serviceActivation: ServiceActivation
  serviceActivationId: string
  createdAt: Date
  closeModal: () => void
}

const ActivationFormDownloadPage: React.FC<Props> = ({
  activationPsp,
  serviceActivation,
  serviceActivationId,
  createdAt,
  closeModal,
}) => {
  const handleSubmit = React.useCallback((values: FormValues, helpers: FormikHelpers<FormValues>) => {
    helpers.setSubmitting(false)
    setDisplayDownloadButton(true)
  }, [])
  const [displayDownloadButton, setDisplayDownloadButton] = React.useState<boolean>(false)
  const formik = useFormik({ initialValues, onSubmit: handleSubmit, validationSchema })

  const pdfDownloadButtonBaseProps = {
    fileName: 'scheda_attivazione.pdf',
    css: downloadButtonStyle,
  }

  const pdfDownloadButtonDocumentProps = {
    activationPsp,
    createdAt,
    patientName: formik.values.name,
    serviceActivationId,
  }

  let pdfDownloadButton
  switch (activationPsp.shortName) {
    case 'MICI':
      pdfDownloadButton = (
        <PDFDownloadLink
          {...pdfDownloadButtonBaseProps}
          document={
            <MiciActivationFormPDF
              {...pdfDownloadButtonDocumentProps}
              serviceActivation={serviceActivation as NewMICIActivation}
            />
          }
        >
          {({ loading }) => (loading ? 'Caricamento della scheda...' : 'Scarica la scheda')}
        </PDFDownloadLink>
      )
      break
    case 'Ematologia':
      pdfDownloadButton = (
        <PDFDownloadLink
          {...pdfDownloadButtonBaseProps}
          document={
            <HaemoActivationFormPDF
              {...pdfDownloadButtonDocumentProps}
              serviceActivation={serviceActivation as NewHaemoActivation}
            />
          }
        >
          {({ loading }) => (loading ? 'Caricamento della scheda...' : 'Scarica la scheda')}
        </PDFDownloadLink>
      )
      break
    case 'Immunologia':
      pdfDownloadButton = (
        <PDFDownloadLink
          {...pdfDownloadButtonBaseProps}
          document={
            <ImmunoActivationFormPDF
              {...pdfDownloadButtonDocumentProps}
              serviceActivation={serviceActivation as NewImmunoActivation}
            />
          }
        >
          {({ loading }) => (loading ? 'Caricamento della scheda...' : 'Scarica la scheda')}
        </PDFDownloadLink>
      )
      break
    case 'SBS':
      pdfDownloadButton = (
        <PDFDownloadLink
          {...pdfDownloadButtonBaseProps}
          document={
            <SBSActivationFormPDF
              {...pdfDownloadButtonDocumentProps}
              serviceActivation={serviceActivation as NewSBSActivation}
            />
          }
        >
          {({ loading }) => (loading ? 'Caricamento della scheda...' : 'Scarica la scheda')}
        </PDFDownloadLink>
      )
      break
    case 'Ipoparatiroidismo':
      pdfDownloadButton = (
        <PDFDownloadLink
          {...pdfDownloadButtonBaseProps}
          document={
            <IpoActivationFormPDF
              {...pdfDownloadButtonDocumentProps}
              serviceActivation={serviceActivation as NewIpoActivation}
            />
          }
        >
          {({ loading }) => (loading ? 'Caricamento della scheda...' : 'Scarica la scheda')}
        </PDFDownloadLink>
      )
      break
    default:
      pdfDownloadButton = undefined
  }

  return (
    <React.Fragment>
      <div
        css={{
          position: 'fixed',
          display: 'flex',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          css={{
            backgroundColor: '#fff',
            padding: 36,
            borderRadius: 5,
            minWidth: 250,
            width: '30%',
            maxWidth: 420,
            textAlign: 'left',
          }}
        >
          {!displayDownloadButton ? (
            <form onSubmit={formik.handleSubmit}>
              <Field>
                <Label text="Nome del Paziente" />
                <Input {...formik.getFieldProps('name')} />
                <ErrorMessage error={fieldErrors('name', formik)} />
              </Field>
              <p css={{ fontSize: 14, color: '#666' }}>
                La generazione del PDF della scheda di attivazione avviene direttamente sul suo dispositivo, per questo
                motivo i nostri server non riceveranno il nome da Lei indicato
              </p>
              <div css={{ display: 'flex', marginTop: 32 }}>
                <button
                  type="button"
                  css={cancelButtonStyle}
                  onClick={() => {
                    closeModal()
                  }}
                >
                  Annulla
                </button>
                <button type="submit" css={tagStyleButtonCss}>
                  Salva
                </button>
              </div>
            </form>
          ) : (
            <div css={{ display: 'flex', justifyContent: 'space-around' }}>
              {pdfDownloadButton}{' '}
              <button
                css={cancelButtonStyle}
                onClick={() => {
                  closeModal()
                }}
              >
                Chiudi
              </button>
            </div>
          )}
        </div>
      </div>
      <div
        css={{
          position: 'fixed',
          display: 'flex',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: '#000',
          opacity: 0.8,
          zIndex: 100,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      ></div>
    </React.Fragment>
  )
}

const initialValues: FormValues = {
  name: '',
}

const validationSchema = yup.object({
  name: yup.string().required('Campo obbligatorio'),
})

const tagStyleButtonCss = {
  backgroundColor: '#fbb600',
  borderRadius: 5,
  padding: '4px 8px',
  color: '#fff',
  textDecoration: 'none',
  fontSize: 18,
  margin: '12px auto',
  border: '2px solid #fbb600',
  cursor: 'pointer',
  marginLeft: 'auto',
  marginRight: 'auto',
  '&:hover': {
    backgroundColor: '#fba600',
    border: '2px solid #fba600',
  },
}

const cancelButtonStyle = {
  ...tagStyleButtonCss,
  backgroundColor: '#fff',
  borderColor: '#f00',
  color: '#f00',
  '&:hover': {
    backgroundColor: '#f00',
    color: '#fff',
  },
}

const downloadButtonStyle = {
  backgroundColor: '#fbb600',
  borderRadius: 5,
  padding: '4px 8px',
  color: '#fff',
  textDecoration: 'none',
  fontSize: 18,
  margin: '12px auto',
  '&:hover': {
    backgroundColor: '#fba600',
  },
}

export default ActivationFormDownloadPage
