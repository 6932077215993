import React from 'react'
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'
import { ActivationPsp } from '../../types'

interface Props {
  activationPsp: ActivationPsp
  serviceActivationId: string
}

const ActivationFormPDF: React.FC<Props> = ({ activationPsp, serviceActivationId }) => (
  <Document>
    <Page orientation="landscape" size="A6" style={styles.body}>
      <View style={styles.container}>
        <Text style={{ fontSize: 14, marginBottom: 16 }}>
          Il Suo Medico l'ha invitata ad aderire al {activationPsp.name}
        </Text>

        <Text style={{ fontSize: 12, marginBottom: 8 }}>La invitiamo a contattare il numero verde</Text>
        <Text style={{ fontSize: 16, color: 'green' }}>{activationPsp.tollFreeNumber}</Text>
        <Text style={{ fontSize: 12, marginTop: 8, marginBottom: 16 }}>
          aperto dal lunedì al venerdì, dalle 9:00 alle 17:00
        </Text>

        <Text style={{ fontSize: 12, marginBottom: 16 }}>
          Al momento dell'adesione, il codice che dovrà comunicare all'operatore è il seguente:
        </Text>

        <Text style={{ fontSize: 18 }}>{serviceActivationId}</Text>
      </View>
    </Page>
  </Document>
)

const styles = StyleSheet.create({
  body: {
    padding: 32,
  },
  container: {
    border: '2pt solid #fbb600',
    borderRadius: 15,
    width: '100%',
    height: '100%',
    padding: 16,
    paddingHorizontal: 32,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    fontSize: 14,
    justifyContent: 'center',
  },
})

export default ActivationFormPDF
