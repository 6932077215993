import React from 'react'

interface Props {
  text: string
}

const Label: React.FC<Props> = ({ text }) => {
  return (
    <label
      css={{
        color: '#fff',
        display: 'block',
        fontSize: 18,
        marginBottom: 4,
        opacity: 0.8,
      }}
    >
      {text}
    </label>
  )
}

export default Label
