import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 18,
    textAlign: 'center',
  },
  undertitle: {
    fontSize: 16,
    textAlign: 'center',
    color: '#666',
  },
  subtitle: {
    fontSize: 14,
    margin: 12,
    borderBottom: '2pt solid #fbb600',
  },
  section: {
    marginTop: 8,
  },
  serviceStatusActivated: {
    color: 'green',
  },
  serviceStatusNotActivated: {
    color: 'red',
  },
  text: {
    margin: 12,
    fontSize: 12,
    textAlign: 'justify',
  },
  field: {
    marginLeft: 22,
    marginRight: 12,
    marginBottom: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  fieldNoSection: {
    margin: 12,
    marginTop: 16,
    marginBottom: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  label: {
    color: '#000',
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'left',
  },
  value: {
    color: '#999',
    fontSize: 12,
    textAlign: 'left',
    display: 'flex',
  },
  footer: {
    position: 'absolute',
    fontSize: 10,
    bottom: 8,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: '#444',
  },
})
