import styled from '@emotion/styled'
import mediaQueries from '../../../styles/media-queries'

const StepImage = styled.img({
  margin: '0 auto',
  width: 100,
  marginBottom: 20,
  [mediaQueries('md')]: {
    width: 120,
    marginBottom: 40,
  },
})

export default StepImage
