import styled from '@emotion/styled'
import mediaQueries from '../../../styles/media-queries'

const PresentationTitle = styled.h2({
  textAlign: 'center',
  fontFamily: 'Lato',
  fontSize: 18,
  lineHeight: 1.4,
  padding: '0 16px',
  fontWeight: 700,
  marginBottom: 16,
  textTransform: 'uppercase',
  [mediaQueries('sm')]: {
    padding: '0 24px',
  },
  [mediaQueries('md')]: {
    padding: '0 40px',
    fontSize: 20,
  },
  [mediaQueries('lg')]: {
    fontSize: 22,
  },
})

export default PresentationTitle
