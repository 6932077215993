import React from 'react'
import RequiredFieldAlert from './required-field-alert'

interface Props {
  text: string
  className?: string
  required?: boolean
}

const Label: React.FC<Props> = ({ text, className, required }) => {
  return (
    <label
      css={{
        color: '#000',
        display: 'block',
        fontSize: 16,
        fontWeight: 600,
        marginBottom: 4,
        opacity: 0.8,
      }}
      className={className}
    >
      {required ? <RequiredFieldAlert /> : null}
      {text}
    </label>
  )
}

export default Label
