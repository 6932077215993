import React from 'react'
import coverImage from '../../../images/form-cover.jpg'
import coverImageMobile from '../../../images/form-cover-mobile.jpg'
import mediaQueries from '../../../styles/media-queries'
import styled from '@emotion/styled'
import { useFormik, FormikHelpers } from 'formik'
import SectionTitle from './section-title'
import Field from './field'
import Label from './label'
import Input from './input'
import ErrorMessage from './error-message'
import Select from './select'
import * as yup from 'yup'
import Textarea from './textarea'
import InfoBox from './info-box'
import PrivacyDisclaimer from '../../privacy-disclaimer'
import Checkbox from './checkbox'
import PrivacyConsent from './privacy-consent'
import { useNavigate } from 'react-router-dom'
import { newSubscriptionForm } from '../../../api'
import SerivceCharacteristics from './service-characteristics'
import { Psp } from '../../../types'

interface FormValues {
  title: string
  firstName: string
  lastName: string
  role: string
  mobile: string
  email: string
  center: string
  ward: string
  centerStreet: string
  centerCity: string
  centerProvince: string
  centerCap: string
  phoneTimePreference: string
  phoneTimeExtraInformations: string
  confirmIdentity: boolean
  requestActivation: boolean
  cgs: boolean
  letterEConsent: 'given' | 'notGiven' | ''
  letterFConsent: 'given' | 'notGiven' | ''
}

const initialValues: FormValues = {
  title: '',
  firstName: '',
  lastName: '',
  role: '',
  mobile: '',
  email: '',
  center: '',
  ward: '',
  centerStreet: '',
  centerCity: '',
  centerProvince: '',
  centerCap: '',
  phoneTimePreference: '',
  phoneTimeExtraInformations: '',
  confirmIdentity: false,
  requestActivation: false,
  cgs: false,
  letterEConsent: '',
  letterFConsent: '',
}

interface Props {
  psp: Psp
}

const SubscriptionForm: React.FC<Props> = ({ psp }) => {
  const navigate = useNavigate()
  const handleSubmit = React.useCallback(
    async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      try {
        await newSubscriptionForm({ ...values, code: psp.code })
        helpers.setSubmitting(false)
        navigate(`/success?email=${psp.email}&tollFreeNumber=${psp.tollFreeNumber}`)
      } catch (error: any) {
        helpers.setSubmitting(false)
        if (error.response.data.error === 'ValidationError') {
          helpers.setFieldError(error.response.data.path, error.response.data.message)
        } else if (error.response.data.error === 'Invalid code') {
          alert('Codice Programma non valido')
        } else if (error.response.data.error === 'malformed request, should provide a code field') {
          alert('Deve essere fornito un codice programma')
        } else {
          alert('Errore di rete')
        }
      }
    },
    [navigate, psp.code, psp.email, psp.tollFreeNumber]
  )

  const formik = useFormik<FormValues>({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: generateValidationSchema(psp),
  })

  const titleOptions = ['Prof.', 'Prof.ssa', 'Dott.', 'Dott.ssa'].map((t) => ({ label: t, value: t }))

  const phoneCallTimePreference = ['Non desidero ricevere la telefonata di benvenuto', '9-12', '12-15', '15-18'].map(
    (p) => ({ label: p, value: p })
  )

  const fieldErrors = React.useCallback(
    (fieldName: keyof FormValues) => {
      return formik.touched[fieldName] ? formik.errors[fieldName] : ''
    },
    [formik.errors, formik.touched]
  )

  return (
    <Container>
      <Wrapper>
        <form onSubmit={formik.handleSubmit}>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              [mediaQueries('md')]: {
                flexDirection: 'row',
              },
            }}
          >
            <FieldGroup>
              <SectionTitle>Medico</SectionTitle>
              <Field>
                <Label text="Titolo" />
                <Select options={titleOptions} defaultValue="Seleziona" required {...formik.getFieldProps('title')} />
                <ErrorMessage error={fieldErrors('title')} />
              </Field>
              <Field>
                <Label text="Nome" />
                <Input {...formik.getFieldProps('firstName')} required />
                <ErrorMessage error={fieldErrors('firstName')} />
              </Field>
              <Field>
                <Label text="Cognome" />
                <Input {...formik.getFieldProps('lastName')} required />
                <ErrorMessage error={fieldErrors('lastName')} />
              </Field>
              <Field>
                <Label text="Ruolo" />
                <Input {...formik.getFieldProps('role')} required />
                <ErrorMessage error={fieldErrors('role')} />
              </Field>
              <Field>
                <Label text="Cellulare" />
                <Input {...formik.getFieldProps('mobile')} required />
                <ErrorMessage error={fieldErrors('mobile')} />
              </Field>
              <Field>
                <Label text="Email" />
                <Input {...formik.getFieldProps('email')} required type="email" />
                <ErrorMessage error={fieldErrors('email')} />
              </Field>
            </FieldGroup>
            <FieldGroup
              css={{
                marginTop: 16,
                [mediaQueries('md')]: {
                  marginTop: 0,
                },
              }}
            >
              <SectionTitle>Struttura Ospedaliera</SectionTitle>
              <Field>
                <Label text="Nome Struttura Ospedaliera" />
                <Input {...formik.getFieldProps('center')} required />
                <ErrorMessage error={fieldErrors('center')} />
              </Field>
              <Field>
                <Label text="Reparto" />
                <Input {...formik.getFieldProps('ward')} required />
                <ErrorMessage error={fieldErrors('ward')} />
              </Field>
              <Field>
                <Label text="Via" />
                <Input {...formik.getFieldProps('centerStreet')} required />
                <ErrorMessage error={fieldErrors('centerStreet')} />
              </Field>
              <Field>
                <Label text="Comune" />
                <Input {...formik.getFieldProps('centerCity')} required />
                <ErrorMessage error={fieldErrors('centerCity')} />
              </Field>
              <Field>
                <Label text="Provincia" />
                <Input {...formik.getFieldProps('centerProvince')} required />
                <ErrorMessage error={fieldErrors('centerProvince')} />
              </Field>
              <Field>
                <Label text="Cap" />
                <Input {...formik.getFieldProps('centerCap')} required />
                <ErrorMessage error={fieldErrors('centerCap')} />
              </Field>
            </FieldGroup>
          </div>
          <div css={{ padding: '0 16px' }}>
            <SectionTitle>Preferenze e informazioni</SectionTitle>
            {(!psp.welcomeCallHidden || psp.welcomeCallRequired) && (
              <Field>
                <Label text="Desidero essere contattato per la telefonata di Benvenuto in questa fascia oraria" />
                <Select
                  {...formik.getFieldProps('phoneTimePreference')}
                  options={phoneCallTimePreference}
                  defaultValue="Selezionare una preferenza di orario"
                  required={psp.welcomeCallRequired && !psp.welcomeCallHidden}
                />
                <ErrorMessage error={fieldErrors('phoneTimePreference')} />
              </Field>
            )}
            <Field>
              <Label text="Inserire eventuali richieste o informazioni in questo campo" />
              <Textarea {...formik.getFieldProps('phoneTimeExtraInformations')} />
              <ErrorMessage error={fieldErrors('phoneTimeExtraInformations')} />
            </Field>
          </div>
          <div css={{ padding: '0 16px' }}>
            <SectionTitle>Caratteristiche del Programma di Supporto al Paziente</SectionTitle>
            <InfoBox css={{ maxHeight: 240, [mediaQueries('md')]: { maxHeight: 320 } }}>
              <SerivceCharacteristics
                pspEmail={psp.email}
                pspDrugs={psp.drugs}
                pspTollFreeNumber={psp.tollFreeNumber}
                baseFontSize={16}
              />
            </InfoBox>
          </div>
          <div css={{ padding: '0 16px', marginTop: 32 }}>
            <SectionTitle>Privacy</SectionTitle>
            <p css={{ color: '#fff', marginBottom: 16, lineHeight: 1.2 }}>
              Tutti i dati personali, comuni e relativi alla salute, necessari allo svolgimento del {psp.name}, saranno
              trattati da EGG S.r.l. in conformità al D.L. 196/2003 e dal nuovo Regolamento UE 2016/679, secondo i
              termini e le modalità indicati nell’Informativa Privacy contenuta nel riquadro sottostante.
            </p>
            <InfoBox css={{ maxHeight: 240, [mediaQueries('md')]: { maxHeight: 320 } }}>
              <PrivacyDisclaimer baseFontSize={16} />
            </InfoBox>
          </div>
          <div css={{ padding: '0 16px', marginTop: 32 }}>
            <SectionTitle>Consensi e Adesione</SectionTitle>
            <Checkbox
              {...formik.getFieldProps('confirmIdentity')}
              label="Io sottoscritto dichiaro che i dati forniti sono veri, completi e riferibili alla mia persona"
              error={fieldErrors('confirmIdentity')}
            />
            <Checkbox
              {...formik.getFieldProps('cgs')}
              label={
                'Si accettano espressamente le "Condizioni generali di utilizzo del Programma" parte del paragrafo "CARATTERISTICHE DEL PROGRAMMA DI SUPPORTO AL PAZIENTE"'
              }
              error={fieldErrors('cgs')}
            />
            <Checkbox
              {...formik.getFieldProps('requestActivation')}
              label="Dopo aver preso visione dell’informativa privacy in materia di trattamento dei dati personali, fornisco i dati richiesti e richiedo che vengano attivati i servizi messi a disposizione dal programma"
              error={fieldErrors('requestActivation')}
            />
            <PrivacyConsent
              {...formik.getFieldProps('letterEConsent')}
              error={fieldErrors('letterEConsent')}
              label="In relazione al trattamento dei Dati Personali per le finalità indicate nell’articolo 2.1 lettera (e) dell’informativa sulla protezione dei Dati Personali:"
            />
            <PrivacyConsent
              {...formik.getFieldProps('letterFConsent')}
              error={fieldErrors('letterFConsent')}
              label="In relazione all’eventuale trattamento dei Dati Personali per le finalità di cui all’articolo 2 lettera (f), autorizzo Egg S.r.l. a trasferire i dati di cui all’articolo 1 a eventuali nuovi Provider di PSP per garantire il prosieguo del servizio:"
            />
          </div>
          <div
            css={{
              display: 'flex',
              padding: 32,
              justifyContent: 'center',
            }}
          >
            <button
              type="submit"
              disabled={formik.isSubmitting}
              css={{
                padding: 16,
                backgroundColor: '#fbb600',
                color: '#333',
                fontSize: 18,
                borderRadius: 10,
                border: 'none',
                cursor: formik.isSubmitting ? 'not-allowed' : 'pointer',
                opacity: formik.isSubmitting ? 0.7 : 1,
              }}
            >
              {formik.isSubmitting ? 'Invio...' : 'Invia Adesione'}
            </button>
          </div>
          {formik.submitCount !== 0 && !formik.isValid && (
            <div css={{ color: '#fb4600', textAlign: 'center', fontSize: 18 }}>
              Attenzione, alcuni campi non sono stati compilati correttamente. E' necessario controllare e correggere
              gli errori per sottomettere nuovamente l'adesione
            </div>
          )}
        </form>
      </Wrapper>
    </Container>
  )
}

function generateValidationSchema(psp: Psp) {
  return yup.object({
    title: yup.string().required('Campo obbligatorio'),
    firstName: yup.string().required('Campo obbligatorio'),
    lastName: yup.string().required('Campo obbligatorio'),
    role: yup.string().required('Campo obbligatorio'),
    mobile: yup.string().required('Campo obbligatorio'),
    email: yup.string().email('Email in un formato non corretto').required('Campo obbligatorio'),
    center: yup.string().required('Campo obbligatorio'),
    ward: yup.string().required('Campo obbligatorio'),
    centerStreet: yup.string().required('Campo obbligatorio'),
    centerCity: yup.string().required('Campo obbligatorio'),
    centerProvince: yup.string().required('Campo obbligatorio'),
    centerCap: yup
      .string()
      .required('Campo obbligatorio')
      .matches(/^\d\d\d\d\d$/, 'Il CAP deve essere un numero di 5 cifre'),
    phoneTimePreference:
      psp.welcomeCallRequired && !psp.welcomeCallHidden ? yup.string().required('Campo obbligatorio') : yup.string(),
    phoneTimeExtraInformations: yup.string(),
    confirmIdentity: yup.boolean().oneOf([true], 'Campo obbligatorio'),
    requestActivation: yup.boolean().oneOf([true], 'Campo obbligatorio'),
    cgs: yup.boolean().oneOf([true], 'Campo obbligatorio'),
    letterEConsent: yup.string().required('Campo obbligatorio').oneOf(['given', 'notGiven'], 'Campo obbligatorio'),
    letterFConsent: yup.string().required('Campo obbligatorio').oneOf(['given', 'notGiven'], 'Campo obbligatorio'),
  })
}

const Container = styled.div({
  backgroundImage: `url(${coverImageMobile})`,
  backgroundSize: 'cover',
  backgroundPosition: 'top center',
  [mediaQueries('sm')]: {
    backgroundImage: `url(${coverImage})`,
  },
})

const Wrapper = styled.div({
  maxWidth: 960,
  margin: '0 auto',
  padding: '40px 16px',
  [mediaQueries('md')]: {
    padding: '80px 32px 40px',
  },
})

const FieldGroup = styled.div({
  width: '100%',
  boxSizing: 'border-box',
  padding: '0 16px',
  [mediaQueries('md')]: {
    width: '50%',
  },
})

export default SubscriptionForm
