import React from 'react'

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string
  label: string
  error?: string
  className?: string
}

const Checkbox: React.FC<Props> = ({ name, label, error, className, ...props }) => {
  return (
    <label
      css={{
        color: '#000',
        display: 'block',
        position: 'relative',
        paddingLeft: 35,
        paddingTop: 2,
        marginBottom: 12,
        marginTop: 12,
        cursor: 'pointer',
        fontSize: 16,
        userSelect: 'none',
        alignItems: 'center',
        lineHeight: 1.2,
      }}
      className={className}
    >
      {label}
      {error ? <span css={{ color: '#fb4600' }}> - {error}</span> : ''}
      <input
        type="checkbox"
        name={name}
        value="true"
        checked={props.value as boolean | undefined}
        {...props}
        css={{
          position: 'absolute',
          opacity: 0,
          cursor: 'pointer',
          height: 0,
          width: 0,
        }}
      />
      <span
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: 14,
          width: 14,
          borderRadius: '100%',
          backgroundColor: props.value ? '#fbb600' : '#fff',
          border: '4px solid #fbb600',
        }}
      >
        <span css={{}} />
      </span>
    </label>
  )
}

export default Checkbox
