import React from 'react'
import PresentationSection from '../presentation-section'
import PresentationTitle from './presentation-title'
import PresentationSectionParagraph from '../presentation-section-paragraph'
import List from './list'
import ListElement from './list-element'
import programImg from '../../../images/icona-progetto.png'
import StepImage from './step-image'

interface Props {
  program: string
}

const ProgramDescription: React.FC<Props> = ({ program }) => {
  switch (program) {
    case 'Ematologia':
      return (
        <PresentationSection>
          <StepImage src={programImg} alt="Servizi Programma" />
          <PresentationTitle>Servizi oggetto del "Programma di Supporto al Paziente in Emofilia"</PresentationTitle>
          <PresentationSectionParagraph>
            Il Programma offre ai Suoi Pazienti in terapia con Advate o Feiba o Adynovi e per i quali Lei ritiene
            opportuna la partecipazione, la possibilità di usufruire dei seguenti servizi:
            <List>
              <ListElement>Training all’autosomministrazione di fattori della coagulazione in emofilia</ListElement>
              <ListElement>
                Somministrazione endovenosa domiciliare di fattori della coagulazione in emofilia
              </ListElement>
              <ListElement>Supporto fisioterapico a domicilio</ListElement>
              <ListElement>Prelievo ematico per il monitoraggio della terapia </ListElement>
              <ListElement>Consegna domiciliare del farmaco</ListElement>
            </List>
            Dopo l'adesione al Programma Le invieremo le schede con la descrizione dettagliata di tutti i servizi.
          </PresentationSectionParagraph>
        </PresentationSection>
      )
    case 'Immunologia':
      return (
        <PresentationSection>
          <StepImage src={programImg} alt="Servizi Programma" />
          <PresentationTitle>Servizi oggetto del "Programma di Supporto al Paziente in Immunologia"</PresentationTitle>
          <PresentationSectionParagraph>
            Il Programma offre ai Suoi Pazienti in terapia con HyQvia e per i quali Lei ritiene opportuna la
            partecipazione, la possibilità di usufruire dei seguenti servizi:
            <List>
              <ListElement>Training per l’autoinfusione di immunoglobuline sottocute</ListElement>
              <ListElement>Somministrazione a domicilio di immunoglobuline sottocute</ListElement>
              <ListElement>
                Training al personale del Centro Ospedaliero per l’utilizzo della pompa infusionale
              </ListElement>
              {/* <ListElement>Supervisione medica alla somministrazione di immunoglobuline sottocutanee</ListElement> */}
              <ListElement>Consegna domiciliare del farmaco</ListElement>
            </List>
            Dopo l'adesione al Programma Le invieremo le schede con la descrizione dettagliata di tutti i servizi.
          </PresentationSectionParagraph>
        </PresentationSection>
      )

    case 'SBS':
      return (
        <PresentationSection>
          <StepImage src={programImg} alt="Servizi Programma" />
          <PresentationTitle>
            Servizi oggetto del "Programma di Supporto al Paziente con Sindrome dell'Intestino Corto"
          </PresentationTitle>
          <PresentationSectionParagraph>
            Il Programma offre ai Suoi Pazienti in terapia con Revestive e per i quali Lei ritiene opportuna la
            partecipazione, la possibilità di usufruire dei seguenti servizi:
            <List>
              <ListElement>
                Training iniziale sulla somministrazione della terapia ed il monitoraggio costante dei parametri clinici
              </ListElement>
              <ListElement>Consegna domiciliare del farmaco</ListElement>
            </List>
            Dopo l'adesione al Programma Le invieremo le schede con la descrizione dettagliata di tutti i servizi.
          </PresentationSectionParagraph>
        </PresentationSection>
      )

    case 'Ipoparatiroidismo':
      return (
        <PresentationSection>
          <StepImage src={programImg} alt="Servizi Programma" />
          <PresentationTitle>
            Servizi oggetto del "Programma di Supporto al Paziente in Ipoparatiroidismo"
          </PresentationTitle>
          <PresentationSectionParagraph>
            Il Programma offre ai Suoi Pazienti in terapia con Natpar e per i quali Lei ritiene opportuna la
            partecipazione, la possibilità di usufruire dei seguenti servizi:
            <List>
              <ListElement>
                Training alla ricostituzione e all’autosomministrazione e monitoraggio dei parametri clinici
              </ListElement>
              <ListElement>Consegna domiciliare del farmaco</ListElement>
            </List>
            Dopo l'adesione al Programma Le invieremo le schede con la descrizione dettagliata di tutti i servizi.
          </PresentationSectionParagraph>
        </PresentationSection>
      )

    case 'MICI':
      return (
        <PresentationSection>
          <StepImage src={programImg} alt="Servizi Programma" />
          <PresentationTitle>Servizi oggetto del "Programma di Supporto al Paziente in MICI"</PresentationTitle>
          <PresentationSectionParagraph>
            Il Programma offre ai Suoi Pazienti in terapia con Entyvio formulazione SC e per i quali Lei ritiene
            opportuna la partecipazione, la possibilità di usufruire dei seguenti servizi:
            <List>
              <ListElement>Training all'autosomministrazione</ListElement>
              <ListElement>Counseling Psicologico</ListElement>
              <ListElement>Corso di Mindfulness</ListElement>
              <ListElement>Supporto Nutrizionale</ListElement>
              <ListElement>Consegna domiciliare del farmaco</ListElement>
            </List>
            Dopo l'adesione al Programma Le invieremo le schede con la descrizione dettagliata di tutti i servizi.
          </PresentationSectionParagraph>
        </PresentationSection>
      )

    default:
      return <div>Descrizione del programma non trovata</div>
  }
}

export default ProgramDescription
