import React from 'react'

const Footer: React.FC<{}> = () => {
  return (
    <div
      css={{
        backgroundColor: '#fbb600',
        display: 'flex',
        height: 80,
        alignItems: 'center',
        marginTop: 'auto',
      }}
    >
      <span
        css={{
          color: '#fff',
          marginLeft: 'auto',
          marginRight: 40,
        }}
      >
        Egg Srl &trade; 2020
      </span>
    </div>
  )
}

export default Footer
