import axios from 'axios'
import { FormikValues } from 'formik'
import { ServiceActivation } from './types'

const verifyCodeEndpoint = process.env.REACT_APP_VERIFY_CODE_ENDPOINT ?? ''
const newSubscriptionEndpoint = process.env.REACT_APP_NEW_SUBSCRIPTION_ENDPOINT ?? ''
const verifySubscriptionIdEndpoint = process.env.REACT_APP_VERIFY_SUBSCRIPTION_ID_ENDPOINT ?? ''
const newServiceActivationEndpoint = process.env.REACT_APP_NEW_SERVICE_ACTIVATION_ENDPOINT ?? ''

export function verifyCode(code: string) {
  return axios.post(verifyCodeEndpoint, { code })
}

interface NewSubscriptionFormPayload extends FormikValues {
  code: string
}

export function newSubscriptionForm(form: NewSubscriptionFormPayload) {
  return axios.post(newSubscriptionEndpoint, form)
}

export function verifySubscriptionId(subscriptionId: string) {
  return axios.post<{ pspName: string; pspShortName: string; pspEmail: string; pspTollFreeNumber: string }>(
    verifySubscriptionIdEndpoint,
    { subscriptionId }
  )
}

export function newServiceActivation(activation: ServiceActivation, subscriptionId: string) {
  return axios.post<{ serviceActivationId: string; createdAt: string }>(newServiceActivationEndpoint, {
    activation,
    subscriptionId,
  })
}
