import { FormikHelpers } from 'formik'
import React from 'react'
import { newServiceActivation } from '../../api'
import { ActivationPsp, ServiceActivation } from '../../types'
import ErrorPage from '../error-page'
import PspHaemoForm from './psp-haemo-form'
import PspImmunoForm from './psp-immuno-form'
import PspIpoForm from './psp-ipo-form'
import PspMiciForm from './psp-mici-form'
import PspSbsForm from './psp-sbs-form'

interface Props {
  activationPsp: ActivationPsp
  subscriptionId: string
  onFormSubmit: (serviceActivation: ServiceActivation, serviceActivationId: string, createdAt: string) => void
}

const Form: React.FC<Props> = ({ onFormSubmit, activationPsp, subscriptionId }) => {
  const handleSubmit = React.useCallback(
    async <T extends ServiceActivation>(values: T, helpers: FormikHelpers<T>) => {
      try {
        const response = await newServiceActivation(values, subscriptionId)
        onFormSubmit(values, response.data.serviceActivationId, response.data.createdAt)
        window.scrollTo({ top: 0 })
      } catch (error: any) {
        helpers.setSubmitting(false)
        if (error.response.data.error === 'ValidationError') {
          helpers.setFieldError(error.response.data.path, error.response.data.message)
        } else if (error.response.data.error === 'Cannot generate activation code') {
          helpers.setStatus({ error: 'Errore nella generazione del codice di attivazione' })
          alert('Errore nella generazione del codice di attivazione')
        } else {
          helpers.setStatus({ error: 'Errore di rete' })
          alert('Errore di rete')
        }
      }
    },
    [onFormSubmit, subscriptionId]
  )

  switch (activationPsp.shortName) {
    case 'Ematologia':
      return <PspHaemoForm onSubmit={handleSubmit} pspName={activationPsp.name} />
    case 'Immunologia':
      return <PspImmunoForm onSubmit={handleSubmit} pspName={activationPsp.name} />
    case 'SBS':
      return <PspSbsForm onSubmit={handleSubmit} pspName={activationPsp.name} />
    case 'Ipoparatiroidismo':
      return <PspIpoForm onSubmit={handleSubmit} pspName={activationPsp.name} />
    case 'MICI':
      return <PspMiciForm onSubmit={handleSubmit} pspName={activationPsp.name} />
    default:
      return (
        <ErrorPage message="Non è stato possibile recuperare il modulo di adesione per il programma, la invitiamo a segnalare l'errore al più presto all'indirizzo email: info.psp@egg.srl" />
      )
  }
}

export default Form
