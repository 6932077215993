import React from 'react'
import { Page, Text, View, Document } from '@react-pdf/renderer'
import { ActivationPsp, NewIpoActivation } from '../../types'
import { styles } from './pdf-form-styles'

interface Props {
  serviceActivation: NewIpoActivation
  serviceActivationId: string
  activationPsp: ActivationPsp
  createdAt: Date
  patientName: string
}

const IpoActivationFormPDF: React.FC<Props> = ({
  serviceActivationId,
  serviceActivation,
  activationPsp,
  createdAt,
  patientName,
}) => (
  <Document>
    <Page style={styles.body}>
      <View>
        <Text style={styles.title}>PROGRAMMA DI SUPPORTO AL PAZIENTE IN {activationPsp.shortName.toUpperCase()}</Text>
        <Text style={styles.undertitle}>Scheda di Attivazione dei Servizi</Text>
        <Text style={styles.text}>
          La presente scheda è stata compilata dal Medico Specialista e trasmessa ad Egg S.r.l. al fine di consentire
          l’attivazione dei Servizi per i Pazienti che aderiscono al {activationPsp.name}.
        </Text>
      </View>
      <View style={{ textAlign: 'center', marginTop: 4, marginBottom: 4 }}>
        <Text style={{ fontWeight: 'bold', fontSize: 16 }}>
          Codice attivazione: <Text>{serviceActivationId}</Text>
        </Text>
        <Text style={{ fontWeight: 'bold', fontSize: 14 }}>
          Paziente: <Text>{patientName}</Text>
        </Text>
        {serviceActivation.patientLocality !== '' && (
          <Text style={{ fontWeight: 'bold', fontSize: 14 }}>
            Comune domicilio Paziente: <Text>{serviceActivation.patientLocality}</Text>
          </Text>
        )}
      </View>

      <View style={styles.section}>
        <Text style={styles.subtitle}>
          Servizio di training iniziale e monitoraggio costante dei parametri clinici:{' '}
          {serviceActivation.ipoparaCareService ? (
            <Text style={styles.serviceStatusActivated}>attivato</Text>
          ) : (
            <Text style={styles.serviceStatusNotActivated}>non attivato</Text>
          )}
        </Text>
        {serviceActivation.ipoparaCareService && (
          <View>
            <View style={styles.field}>
              <Text style={styles.label}>Dosaggio Natpar (mcg): </Text>
              <Text style={styles.value}>{serviceActivation.dose || '-'}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>Note sul dosaggio: </Text>
              <Text style={styles.value}>{serviceActivation.doseNote || '-'}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>Valore della calcemia al momento della presa in carico (mg/dl): </Text>
              <Text style={styles.value}>{serviceActivation.calcemiaAtTakingCharge || '-'}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>
                Indicare eventuali informazioni relative al quadro clinico del Paziente che devono essere prese in
                considerazione durante il training iniziale sulla somministrazione della terapia, nelle visite
                domiciliari o nelle telefonate di controllo:{' '}
              </Text>
              <Text style={styles.value}>{serviceActivation.sanitaryInformations || '-'}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>
                Le indicazioni sanitarie fornite sono antecedenti e non correlate all’uso di natpar{' '}
              </Text>
              <Text style={styles.value}>{serviceActivation.sanitaryInformationsPreTherapy ? 'Si' : 'No'}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>
                Indicare eventuali variazioni richieste rispetto al protocollo standard delle visite domiciliari e delle
                telefonate di controllo{' '}
              </Text>
              <Text style={styles.value}>{serviceActivation.monitoringRequests || '-'}</Text>
            </View>
          </View>
        )}
      </View>

      <View style={styles.section}>
        <Text style={styles.subtitle}>
          Servizio di consegna domiciliare del farmaco:{' '}
          {serviceActivation.drugDeliveryService ? (
            <Text style={styles.serviceStatusActivated}>attivato</Text>
          ) : (
            <Text style={styles.serviceStatusNotActivated}>non attivato</Text>
          )}
        </Text>
        {serviceActivation.drugDeliveryService && (
          <View>
            <View style={styles.field}>
              <Text style={styles.label}>Farmacia per il ritiro: </Text>
              <Text style={styles.value}>
                {serviceActivation.pharmacyOptions === 'Gli estremi della Farmacia sono i seguenti'
                  ? serviceActivation.pharmacyInformations
                  : serviceActivation.pharmacyOptions}
              </Text>
            </View>
          </View>
        )}
      </View>

      <View style={styles.fieldNoSection}>
        <Text style={styles.label}>
          Richiedo di essere informato via email quando il Paziente completerà l'adesione:
        </Text>
        <Text style={styles.value}>{serviceActivation.requestFollowupOnPatientEnrollment ? 'Si' : 'No'}</Text>
      </View>

      <View>
        <Text style={styles.text}>
          Io sottoscritto/a, in qualità di Medico Specialista iscritto al {activationPsp.name}, ricevuta richiesta da
          parte del mio Paziente di attivare i servizi inclusi nel Programma, confermo che il Paziente è idoneo a
          ricevere i servizi qui selezionati secondo quanto previsto dalla Scheda Tecnica di Prodotto ed alle
          indicazioni sopra fornitevi. Sono inoltre consapevole che i servizi sopra indicati possono essere attivati
          solo per i Pazienti che hanno già iniziato la terapia con {`Natpar`} e che tali servizi sono da considerarsi
          come addizionali e non sostitutivi a quelli in capo all'Ente o al SSN.
        </Text>
      </View>
      <View style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
        <Text style={styles.text}>
          Data di compilazione: {createdAt.toLocaleDateString()} {createdAt.toLocaleTimeString()}
        </Text>
      </View>
    </Page>
  </Document>
)

export default IpoActivationFormPDF
