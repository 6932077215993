import React from 'react'
import { useParams } from 'react-router-dom'
import { verifySubscriptionId } from '../../api'
import Loading from '../loading'
import ErrorPage from '../error-page'
import ActivationForm from './activation-form'
import { ActivationPsp } from '../../types'

interface Props {}

const NewServiceActivationPage: React.FC<Props> = () => {
  const { subscriptionId } = useParams<{ subscriptionId: string }>()
  const [error, setError] = React.useState<string | null>(null)
  const [activationPsp, setActivationPsp] = React.useState<ActivationPsp | null>(null)
  const [loading, setLoading] = React.useState<boolean>(true)

  React.useEffect(() => {
    if (subscriptionId) {
      verifySubscriptionId(subscriptionId)
        .then((response) => {
          setActivationPsp({
            email: response.data.pspEmail,
            name: response.data.pspName,
            shortName: response.data.pspShortName,
            tollFreeNumber: response.data.pspTollFreeNumber,
          })
          setLoading(false)
        })
        .catch(() => {
          setError(
            "Impossibile verificare il codice di adesione, la invitiamo a contattarci all'indirizzo email: info.psp@egg.srl"
          )
          setLoading(false)
        })
    } else {
      setError(
        "Impossibile verificare il codice di adesione, la invitiamo a contattarci all'indirizzo email: info.psp@egg.srl"
      )
      setLoading(false)
    }
  }, [subscriptionId])

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <ErrorPage message={error} />
  }

  if (!subscriptionId) {
    return (
      <ErrorPage
        message={
          "Impossibile verificare il codice di adesione, la invitiamo a contattarci all'indirizzo email: info.psp@egg.srl"
        }
      />
    )
  }

  if (!activationPsp) {
    return (
      <ErrorPage message="Errore nella verifica del codice di adesione, impossibile individuare il programma corretto, la invitiamo a contattarci all'indirizzo email: info.psp@egg.srl" />
    )
  }

  return <ActivationForm activationPsp={activationPsp} subscriptionId={subscriptionId} />
}

export default NewServiceActivationPage
