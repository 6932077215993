import React from 'react'
import Header from '../header'
import Footer from '../footer'
import { useParams } from 'react-router-dom'
import PrivacyPN120 from './privacy-pn120'
import PrivacyTKP2107 from './privacy-tkp2107'

const PatientPrivacyPage: React.FC<{}> = () => {
  const { privacyCode } = useParams<{ privacyCode: string }>()

  return (
    <div>
      <Header title="Informativa Privacy Paziente" />
      <div css={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <div
          css={{
            maxWidth: 960,
            padding: 16,
            margin: '16px auto',
          }}
        >
          {privacyCode?.toLowerCase() === 'pn120' ? (
            <PrivacyPN120 baseFontSize={16} />
          ) : privacyCode?.toLowerCase() === 'tkp2107' ? (
            <PrivacyTKP2107 baseFontSize={16} />
          ) : (
            <div css={{ height: '77vh' }}>
              <p css={{ fontSize: '20px', fontWeight: 'bold' }}>Codice privacy errato</p>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default PatientPrivacyPage
