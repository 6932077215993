import styled from '@emotion/styled'

const Input = styled.input({
  height: 28,
  fontSize: 16,
  width: '100%',
  opacity: 0.6,
  border: '1px solid #fb4600',
  borderRadius: 3,
  '&:focus': {
    outline: 'none',
  },
  padding: '2px 3px',
  boxSizing: 'content-box',
  appearance: 'none',
  backgroundColor: '#fff',
  color: '#000',
})

export default Input
