import React from 'react'
import SubscriptionContent from './subscription-content'
import Cover from './cover'
import styled from '@emotion/styled'
import Header from '../header'
import SubcriptionForm from './subscription-form'
import Footer from '../footer'
import { Psp } from '../../types'

interface Props {
  psp: Psp
}

const SubscriptionPage: React.FC<Props> = ({ psp }) => {
  const [coverExited, setCoverExited] = React.useState(false)

  const handlePageExit = React.useCallback(() => {
    setCoverExited(true)
  }, [])

  React.useLayoutEffect(() => {
    if (coverExited) {
      window.scrollTo({ top: 0 })
    }
  }, [coverExited])

  return (
    <div>
      <CoverContainer coverExited={coverExited}>
        <Cover psp={psp} onPageExit={handlePageExit} />
      </CoverContainer>
      <PageContainer coverExited={coverExited}>
        <Header title={`Adesione ${psp.name}`} />
        <SubscriptionContent psp={psp} />
        <SubcriptionForm psp={psp} />
        <Footer />
      </PageContainer>
    </div>
  )
}

interface CoverContainerProps {
  coverExited: boolean
}

const CoverContainer = styled.div<CoverContainerProps>(({ coverExited }) => ({
  display: coverExited ? 'none' : 'block',
  opacity: coverExited ? 0 : 1,
}))

interface PageContainerProps {
  coverExited: boolean
}

const PageContainer = styled.div<PageContainerProps>(({ coverExited }) => ({
  position: coverExited ? 'relative' : 'fixed',
  height: coverExited ? '100%' : '100vh',
  width: '100%',
  top: 0,
  left: 0,
  zIndex: coverExited ? 0 : -1,
}))

export default SubscriptionPage
