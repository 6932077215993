import React from 'react'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { ActivationPsp, ServiceActivation } from '../../types'
import mediaQueries from '../../styles/media-queries'
import styled from '@emotion/styled'
import PatientCardPDF from './patient-card-pdf'
import successIcon from '../../images/success-icon.png'
import { keyframes } from '@emotion/react'
import ActivationFormDownloadPage from './activation-form-download-page'

interface Props {
  activationPsp: ActivationPsp
  serviceActivation: ServiceActivation
  serviceActivationId: string
  createdAt: Date | null
}

const FormSubmitted: React.FC<Props> = ({ activationPsp, serviceActivation, serviceActivationId, createdAt }) => {
  const [displayActivationFormDownload, setDisplayActivationFormDownload] = React.useState<boolean>(false)
  const oggettoEmailPaziente = `Aderire al ${activationPsp.name}`
  const testoEmailPaziente = `Gentilissimo, per aderire al ${activationPsp.name} le chiedo di contattare Egg srl al numero verde ${activationPsp.tollFreeNumber}, aperto dal lunedì al venerdì dalle ore 9:00 alle ore 17:00. Il codice da comunicare all'operatore al momento dell'desione è il seguente: ${serviceActivationId}`

  return (
    <div
      css={{
        maxWidth: 960,
        margin: '0 auto',
        padding: '40px 8px',
        fontSize: 22,
        lineHeight: 1.5,
        [mediaQueries('md')]: {
          padding: '80px 16px 16px',
        },
      }}
    >
      <div css={{ textAlign: 'center', marginTop: -64 }}>
        <div
          css={{
            margin: '32px 8px',
          }}
        >
          <img
            css={{
              height: 150,
              width: 150,
              marginBottom: 32,
              animation: `${popAnimation} 0.6s ease-in`,
            }}
            src={successIcon}
            alt="Successo!"
          />
          <Title>
            <p>Scheda compilata con successo!</p>
            <p>Che cosa fare ora?</p>
          </Title>
        </div>

        <Section>
          <div>
            <b>Comunichi al suo paziente il codice anonimo</b> associato all'attivazione dei servizi appena compilata e
            gli chieda di telefonare al numero verde del Programma{' '}
            <span css={{ fontWeight: 600, color: '#03a811', display: 'inline-block' }}>
              {' '}
              {activationPsp.tollFreeNumber}{' '}
            </span>{' '}
            dal lunedì al venerdì, dalle 9:00 alle 17:00)
          </div>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              lineHeight: 1.5,
              margin: '48px 8px',
            }}
          >
            <span css={{ fontSize: 24, fontWeight: 400, color: '#999' }}>Codice da comunicare al Paziente:</span>
            <span css={{ fontSize: 38, fontWeight: 600 }}>{serviceActivationId}</span>
          </div>
          <div css={{ display: 'flex', alignItems: 'center', marginTop: 32, flexDirection: 'column' }}>
            <a css={tagStyleButtonCss} href={`mailto:?subject=${oggettoEmailPaziente}&body=${testoEmailPaziente}`}>
              Invia il codice via email
            </a>
            <span css={{ fontSize: 20 }}>oppure</span>
            <button
              css={tagStyleButtonCss}
              onClick={() => {
                navigator.clipboard.writeText(testoEmailPaziente)
              }}
            >
              Copia il testo per inviarlo al Paziente via messaggio
            </button>
            <span css={{ fontSize: 20 }}>oppure</span>
            <PDFDownloadLink
              document={<PatientCardPDF activationPsp={activationPsp} serviceActivationId={serviceActivationId} />}
              fileName="patient_card.pdf"
              css={tagStyleButtonCss}
            >
              {({ loading }) =>
                loading ? 'Caricamento della card Paziente...' : 'Scarica la card Paziente con il codice'
              }
            </PDFDownloadLink>
          </div>
        </Section>
        <Section>
          <div
            css={{
              fontSize: 18,
            }}
          >
            Le consigliamo inoltre di scaricare qui di seguito il PDF della scheda appena compilata indicando il nome
            del paziente a cui fa riferimento, ciò allo scopo di verificare che i dati forniti siano corretti e per
            tenerne una copia
          </div>
          <div css={{ display: 'flex', marginTop: 32 }}>
            <button
              css={tagStyleButtonCss}
              onClick={() => {
                setDisplayActivationFormDownload((pv) => !pv)
              }}
            >
              Scarica in PDF
            </button>
          </div>
          {displayActivationFormDownload && (
            <ActivationFormDownloadPage
              closeModal={() => {
                setDisplayActivationFormDownload(false)
              }}
              activationPsp={activationPsp}
              createdAt={createdAt || new Date()}
              serviceActivation={serviceActivation}
              serviceActivationId={serviceActivationId}
            />
          )}
        </Section>
      </div>
    </div>
  )
}

const Section = styled.div({
  margin: '32px 8px',
  display: 'flow',
  flexDirection: 'column',
  border: '2px solid #fbb600',
  paddingTop: 0,
  borderRadius: 5,
  padding: '32px 24px',

  b: {
    borderBottom: '3px solid #fbb600',
    fontWeight: 600,
  },
})

const Title = styled.h1({
  fontSize: 32,
  marginBottom: 64,
})

const tagStyleButtonCss = {
  backgroundColor: '#fbb600',
  borderRadius: 5,
  padding: '4px 8px',
  color: '#fff',
  textDecoration: 'none',
  fontSize: 18,
  margin: '12px auto',
  border: '2px solid #fbb600',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#fba600',
    border: '2px solid #fba600',
  },
}

const popAnimation = keyframes`
  0% {
    transform: scale(0, 0);
  }

  80% {
    transform: scale(1.2, 1.2);
  }

  90% {
    transform: scale(0.9, 0.9);
  }

  100% {
    transform: scale(1, 1);
  }
`

export default FormSubmitted
