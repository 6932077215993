import { utcToZonedTime } from 'date-fns-tz'
import React from 'react'
import { ActivationPsp, ServiceActivation } from '../../types'
import Footer from '../footer'
import Header from '../header'
import Form from './form'
import FormSubmitted from './form-submitted'

interface Props {
  activationPsp: ActivationPsp
  subscriptionId: string
}

const ActivationForm: React.FC<Props> = ({ activationPsp, subscriptionId }) => {
  const [serviceActivation, setServiceActivation] = React.useState<ServiceActivation | null>(null)
  const [serviceActivationId, setServiceActivationId] = React.useState<string | null>(null)
  const [createdAt, setCreatedAt] = React.useState<Date | null>(null)
  const handleFormSubmit = React.useCallback((act: ServiceActivation, srvActId: string, crAt: string) => {
    setServiceActivation(act)
    setServiceActivationId(srvActId)
    setCreatedAt(utcToZonedTime(crAt, 'Europe/Rome'))
  }, [])
  return (
    <div css={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Header title={`Attivazione Servizi per Paziente in ${activationPsp.shortName}`} />
      <div css={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <div
          css={{
            maxWidth: 960,
            padding: 16,
            margin: '16px auto',
          }}
        >
          {!!serviceActivation && !!serviceActivationId ? (
            <FormSubmitted
              activationPsp={activationPsp}
              serviceActivation={serviceActivation}
              serviceActivationId={serviceActivationId}
              createdAt={createdAt}
            />
          ) : (
            <Form onFormSubmit={handleFormSubmit} subscriptionId={subscriptionId} activationPsp={activationPsp} />
          )}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ActivationForm
