import React from 'react'

interface Props {
  formik: { isSubmitting: boolean }
}

const SubmitButton: React.FC<Props> = ({ formik }) => (
  <div
    css={{
      display: 'flex',
      padding: 32,
      justifyContent: 'center',
    }}
  >
    <button
      type="submit"
      disabled={formik.isSubmitting}
      css={{
        padding: 16,
        backgroundColor: '#fbb600',
        color: '#333',
        fontSize: 18,
        borderRadius: 10,
        border: 'none',
        cursor: formik.isSubmitting ? 'not-allowed' : 'pointer',
        opacity: formik.isSubmitting ? 0.7 : 1,
      }}
    >
      {formik.isSubmitting ? 'Invio...' : 'Invia Adesione'}
    </button>
  </div>
)

export default SubmitButton
