import React from 'react'
import { FormikHelpers, useFormik } from 'formik'
import * as yup from 'yup'
import { NewImmunoActivation } from '../../types'
import { Container, FormSection, genOptions, fieldErrors, FormErrors } from './form-helpers'
import FormIntroduction from './form-introduction'
import SectionTitle from './section-title'
import Checkbox from './checkbox'
import Field from './field'
import Label from './label'
import Input from './input'
import ErrorMessage from './error-message'
import Radio from './radio'
import Textarea from './textarea'
import ConsentSection from './consent-section'
import SubmitButton from './submit-button'
import CheckboxGroup from './checkbox-group'
import SectionDivider from './section-divider'
import RequestFollowupOnPatientEnrollment from './request-followup-on-patient-enrollment'
import PatientLocalityField from './patient-locality-field'

type FormValues = NewImmunoActivation

interface Props {
  onSubmit: (activation: NewImmunoActivation, helpers: FormikHelpers<NewImmunoActivation>) => Promise<void>
  pspName: string
}

const PspImmunoForm: React.FC<Props> = ({ onSubmit, pspName }) => {
  const handleSubmit = React.useCallback(
    async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      await onSubmit(values, helpers)
      helpers.setSubmitting(false)
    },
    [onSubmit]
  )

  const formik = useFormik<FormValues>({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
  })

  return (
    <Container>
      <FormIntroduction />
      <form onSubmit={formik.handleSubmit}>
        <FormSection>
          <SectionTitle css={{ marginTop: 32 }}>Servizio di training</SectionTitle>
          <Checkbox
            {...formik.getFieldProps('trainingService')}
            label="Attivare il servizio di training per l’auto infusione a domicilio di immunoglobuline sottocutanee"
            error={fieldErrors('trainingService', formik)}
            css={{ marginBottom: 32 }}
          />
          {formik.values.trainingService && (
            <React.Fragment>
              <CheckboxGroup>
                <Label text="Primi training al Centro" />
                <Checkbox
                  {...formik.getFieldProps('firstTrainingsAtCenter')}
                  label="Si richiede che i primi training vengano svolti al Centro"
                  error={fieldErrors('firstTrainingsAtCenter', formik)}
                />
              </CheckboxGroup>
              {formik.values.firstTrainingsAtCenter && (
                <Field>
                  <Label
                    text="Indicare il numero di training che dovranno essere effettuati presso il Centro"
                    required
                  />
                  <Input {...formik.getFieldProps('numberOfTrainingsAtCenter')} />
                  <ErrorMessage error={fieldErrors('numberOfTrainingsAtCenter', formik)} />
                </Field>
              )}
              <Radio
                {...formik.getFieldProps('patientAutonomyExpectation')}
                label="Propensione del Paziente (o del suo Caregiver) a diventare autonomo nell'auto infusione sottocutanea di immunoglobuline"
                options={patientAutonomyExpectationOptions}
                error={fieldErrors('patientAutonomyExpectation', formik)}
                required
              />
              <Field>
                <Label text="Indicare eventuali elementi che potrebbero influire positivamente o negativamente sulla capacità del Paziente o del Caregiver di raggiungere un buon livello di autonomia entro i 6 training previsti dal Servizio" />
                <Textarea {...formik.getFieldProps('trainingAutonomyNote')} />
                <ErrorMessage error={fieldErrors('trainingAutonomyNote', formik)} />
              </Field>
            </React.Fragment>
          )}
        </FormSection>
        <FormSection>
          <SectionTitle>Servizio di somministrazione</SectionTitle>
          <Checkbox
            {...formik.getFieldProps('infusionService')}
            label="Attivare il Servizio di somministrazione a domicilio di immunoglobuline sottocutanee"
            error={fieldErrors('infusionService', formik)}
          />
        </FormSection>
        <FormSection>
          <SectionTitle css={{ marginTop: 32 }}>Consegna domiciliare del farmaco</SectionTitle>
          <Checkbox
            {...formik.getFieldProps('drugDeliveryService')}
            label="Attivare il servizio di consegna domiciliare del farmaco"
            error={fieldErrors('drugDeliveryService', formik)}
          />
          {formik.values.drugDeliveryService && (
            <React.Fragment>
              <Radio
                label="Farmacia per il ritiro"
                {...formik.getFieldProps('pharmacyOptions')}
                options={pharmacyOptions}
                error={fieldErrors('pharmacyOptions', formik)}
                required
              />
              {formik.values.pharmacyOptions === 'Gli estremi della Farmacia sono i seguenti' && (
                <Field css={{ marginTop: 16 }}>
                  <Label text="Indicare gli estremi della Farmacia" required />
                  <Textarea
                    {...formik.getFieldProps('pharmacyInformations')}
                    placeholder="Nome, contatto telefonico, email, indirizzo, eventuale persona di riferimento, ..."
                  />
                  <ErrorMessage error={fieldErrors('pharmacyInformations', formik)} />
                </Field>
              )}
            </React.Fragment>
          )}
        </FormSection>
        {(formik.values.trainingService || formik.values.infusionService) && (
          <FormSection>
            <SectionTitle>Piano Terapeutico</SectionTitle>
            <Field>
              <Label text="Diagnosi - indicare la patologia" required />
              <Input {...formik.getFieldProps('diagnosys')} />
              <ErrorMessage error={fieldErrors('diagnosys', formik)} />
            </Field>
            <Radio
              {...formik.getFieldProps('immunodeficiencyType')}
              label="Indicare il tipo di immunodeficienza"
              options={immunodeficiencyTypeOptions}
              error={fieldErrors('immunodeficiencyType', formik)}
              required
            />
            <Field>
              <Label text="Farmaco" required />
              <Input {...formik.getFieldProps('drugName')} />
              <ErrorMessage error={fieldErrors('drugName', formik)} />
            </Field>
            <Radio
              {...formik.getFieldProps('pumpModel')}
              label="Modello di pompa infusionale"
              options={pumpModelOptions}
              error={fieldErrors('pumpModel', formik)}
              required
            />
            {formik.values.pumpModel === 'Altro' && (
              <Field>
                <Label text="Specificare il modello di pompa infusionale" required />
                <Input {...formik.getFieldProps('pumpModelOther')} />
                <ErrorMessage error={fieldErrors('pumpModelOther', formik)} />
              </Field>
            )}
            <SectionDivider text="Schema di Ramp-up" />
            <Field>
              <Label text="Ramp-up: 1° somministrazione - dose" />
              <Input {...formik.getFieldProps('rampupFirstDose')} />
              <ErrorMessage error={fieldErrors('rampupFirstDose', formik)} />
            </Field>
            <Field>
              <Label text="Ramp-up: 1° somministrazione - data o tempo di intervallo dalla precedente somministrzione" />
              <Input {...formik.getFieldProps('rampupFirstTiming')} />
              <ErrorMessage error={fieldErrors('rampupFirstTiming', formik)} />
            </Field>
            <Field>
              <Label text="Ramp-up: 2° somministrazione - dose" />
              <Input {...formik.getFieldProps('rampupSecondDose')} />
              <ErrorMessage error={fieldErrors('rampupSecondDose', formik)} />
            </Field>
            <Field>
              <Label text="Ramp-up: 2° somministrazione - data o tempo di intervallo dalla precedente somministrzione" />
              <Input {...formik.getFieldProps('rampupSecondTiming')} />
              <ErrorMessage error={fieldErrors('rampupSecondTiming', formik)} />
            </Field>
            <Field>
              <Label text="Ramp-up: 3° somministrazione - dose" />
              <Input {...formik.getFieldProps('rampupThirdDose')} />
              <ErrorMessage error={fieldErrors('rampupThirdDose', formik)} />
            </Field>
            <Field>
              <Label text="Ramp-up: 3° somministrazione - data o tempo di intervallo dalla precedente somministrzione" />
              <Input {...formik.getFieldProps('rampupThirdTiming')} />
              <ErrorMessage error={fieldErrors('rampupThirdTiming', formik)} />
            </Field>
            <Field>
              <Label text="Ulteriori indicazioni relative al Ramp-up" />
              <Textarea {...formik.getFieldProps('rampupOtherIndications')} />
              <ErrorMessage error={fieldErrors('rampupOtherIndications', formik)} />
            </Field>

            <SectionDivider text="Schema di mantenimento" />
            <Field>
              <Label text="Dose di mantenimento" required />
              <Input {...formik.getFieldProps('maintenanceDose')} />
              <ErrorMessage error={fieldErrors('maintenanceDose', formik)} />
            </Field>
            <Field>
              <Label text="Frequenza di somministrazione durante il mantenimento" required />
              <Input {...formik.getFieldProps('maintenanceFrequency')} />
              <ErrorMessage error={fieldErrors('maintenanceFrequency', formik)} />
            </Field>
            <Field>
              <Label text="Velocità di infusione durante il mantenimento" />
              <Input {...formik.getFieldProps('maintenanceVelocity')} />
              <ErrorMessage
                hint="Se differente da quanto previsto nell'RCP di prodotto"
                error={fieldErrors('maintenanceVelocity', formik)}
              />
            </Field>
            <SectionDivider />
            <Field>
              <Label text="Indicare il quadro clinico, eventuali patologie e indicazioni sanitarie che devono essere prese in considerazione prima, durante e dopo la somministrazione" />
              <Textarea {...formik.getFieldProps('sanitaryInformations')} />
              <ErrorMessage error={fieldErrors('sanitaryInformations', formik)} />
            </Field>
            {formik.values.sanitaryInformations !== '' && (
              <Checkbox
                {...formik.getFieldProps('sanitaryInformationsPreTherapy')}
                label={`Le indicazioni sanitarie fornite sono antecedenti e non correlate all’uso del farmaco indicato nella sezione "piano terapeutico"`}
                error={fieldErrors('sanitaryInformationsPreTherapy', formik)}
                css={{ marginTop: -24 }}
              />
            )}
            <Radio
              {...formik.getFieldProps('visitDateChangeAlertOption')}
              label="Nel caso in cui un trainig o una somministrazione non possa essere effettuata nel giorno previsto dal piano terapeutico e quindi la somministrazione subisca uno spostamento desidera essere informato:"
              options={visitDateChangeAlertOptionOptions}
              error={fieldErrors('visitDateChangeAlertOption', formik)}
              required
            />
            {formik.values.visitDateChangeAlertOption ===
              'Essere avvisato se si esce da un range temporale (specificare sotto)' && (
              <React.Fragment>
                <Field>
                  <Label text="N. di giorni prima la somministrazione per cui si chiede di essere avvisati" required />
                  <Input {...formik.getFieldProps('visitDateChangeAlertDaysBefore')} type="number" />
                  <ErrorMessage error={fieldErrors('visitDateChangeAlertDaysBefore', formik)} />
                </Field>
                <Field>
                  <Label text="N. di giorni dopo la somministrazione per cui si chiede di essere avvisati" required />
                  <Input {...formik.getFieldProps('visitDateChangeAlertDaysAfter')} type="number" />
                  <ErrorMessage error={fieldErrors('visitDateChangeAlertDaysAfter', formik)} />
                </Field>
              </React.Fragment>
            )}
            <Radio
              {...formik.getFieldProps('visitDateChangeAlertType')}
              label="Indicare le modalità con cui desidera essere informato"
              options={visitDateChangeAlertTypeOptions}
              error={fieldErrors('visitDateChangeAlertType', formik)}
              required
            />
            <CheckboxGroup>
              <Label text="Richiesta contatto telefonico dopo prima attività domiciliare" />
              <Checkbox
                {...formik.getFieldProps('phoneCallAfterFirstVisit')}
                label="Desidero essere contattato telefonicamente dopo la prima attività al domicilio del Paziente per ricevere un feedback dall’Infermiere"
                error={fieldErrors('phoneCallAfterFirstVisit', formik)}
              />
            </CheckboxGroup>
          </FormSection>
        )}
        <PatientLocalityField
          formik={formik}
          visible={formik.values.trainingService || formik.values.infusionService}
        />
        <RequestFollowupOnPatientEnrollment formik={formik} />
        <ConsentSection
          {...formik.getFieldProps('physicianConfirmation')}
          pspName={pspName}
          drugName="HyQvia"
          error={fieldErrors('physicianConfirmation', formik)}
        />
        <SubmitButton formik={formik} />
        <FormErrors formik={formik} />
      </form>
    </Container>
  )
}

const pharmacyValues = [
  'Il Paziente conosce gli estremi della Farmacia presso cui effettuare il ritiro della terapia',
  'Gli estremi della Farmacia sono i seguenti',
]
const pharmacyOptions = genOptions(pharmacyValues)

const patientAutonomyExpectationValues = ['Nessuna', 'Scarsa', 'Discreta', 'Buona']
const patientAutonomyExpectationOptions = genOptions(patientAutonomyExpectationValues)

const immunodeficiencyTypeValues = ['PID', 'SID', 'Altro']
const immunodeficiencyTypeOptions = genOptions(immunodeficiencyTypeValues)

const pumpModelValues = ['Mini Rythmic PN+', 'Crono S-PID 100', 'Altro']
const pumpModelOptions = genOptions(pumpModelValues)

const visitDateChangeAlertOptionValues = [
  'Essere avvisato se si esce da un range temporale (specificare sotto)',
  'Sempre',
]
const visitDateChangeAlertOptionOptions = genOptions(visitDateChangeAlertOptionValues)

const visitDateChangeAlertTypeValues = ['Email', 'Telefonata']
const visitDateChangeAlertTypeOptions = genOptions(visitDateChangeAlertTypeValues)

const validationSchema = yup.object({
  // Training Service
  trainingService: yup
    .boolean()
    .when(['infusionService', 'drugDeliveryService'], {
      is: (iS: boolean, ddS: boolean) => !iS && !ddS,
      then: yup.boolean().oneOf([true], 'Necessario selezionare almeno un servizio'),
      otherwise: yup.boolean(),
    })
    .when('infusionService', {
      is: true,
      then: yup
        .boolean()
        .oneOf([false], 'Impossibile attivare contemporaneamente il servizio di training e quello di somministrazione'),
      otherwise: yup.boolean(),
    }),

  firstTrainingsAtCenter: yup.boolean(),
  numberOfTrainingsAtCenter: yup.string().when('firstTrainingsAtCenter', {
    is: true,
    then: yup.string().required('Campo obbligatorio'),
    otherwise: yup.string().notRequired(),
  }),
  patientAutonomyExpectation: yup.string().when('trainingService', {
    is: true,
    then: yup
      .string()
      .required('Campo obbligatorio')
      .oneOf(patientAutonomyExpectationValues, 'Inserire il valore corretto'),
    otherwise: yup.string().notRequired(),
  }),
  trainingAutonomyNote: yup.string().notRequired(),

  // InfusionService
  infusionService: yup.boolean(),

  // Therapeutic Plan
  diagnosys: yup.string().when(['trainingService', 'infusionService'], {
    is: (tS: boolean, iS: boolean) => tS || iS,
    then: yup.string().required('Campo obbligatorio'),
    otherwise: yup.string().notRequired(),
  }),
  immunodeficiencyType: yup.string().when(['trainingService', 'infusionService'], {
    is: (tS: boolean, iS: boolean) => tS || iS,
    then: yup.string().required('Campo obbligatorio').oneOf(immunodeficiencyTypeValues, 'Inserire il valore corretto'),
    otherwise: yup.string().notRequired(),
  }),
  drugName: yup.string().when(['trainingService', 'infusionService'], {
    is: (tS: boolean, iS: boolean) => tS || iS,
    then: yup.string().required('Campo obbligatorio'),
    otherwise: yup.string().notRequired(),
  }),
  pumpModel: yup.string().when(['trainingService', 'infusionService'], {
    is: (tS: boolean, iS: boolean) => tS || iS,
    then: yup.string().required('Campo obbligatorio').oneOf(pumpModelValues, 'Inserire il valore corretto'),
    otherwise: yup.string().notRequired(),
  }),
  pumpModelOther: yup.string().when('pumpModel', {
    is: 'Altro',
    then: yup.string().required('Campo obbligatorio'),
    otherwise: yup.string().notRequired(),
  }),
  rampupFirstDose: yup.string().notRequired(),
  rampupFirstTiming: yup.string().notRequired(),
  rampupSecondDose: yup.string().notRequired(),
  rampupSecondTiming: yup.string().notRequired(),
  rampupThirdDose: yup.string().notRequired(),
  rampupThirdTiming: yup.string().notRequired(),
  rampupOtherIndications: yup.string().notRequired(),

  maintenanceDose: yup.string().when(['trainingService', 'infusionService'], {
    is: (tS: boolean, iS: boolean) => tS || iS,
    then: yup.string().required('Campo obbligatorio'),
    otherwise: yup.string().notRequired(),
  }),
  maintenanceFrequency: yup.string().when(['trainingService', 'infusionService'], {
    is: (tS: boolean, iS: boolean) => tS || iS,
    then: yup.string().required('Campo obbligatorio'),
    otherwise: yup.string().notRequired(),
  }),
  maintenanceVelocity: yup.string().notRequired(),

  sanitaryInformations: yup.string().notRequired(),
  sanitaryInformationsPreTherapy: yup.boolean(),

  visitDateChangeAlertOption: yup.string().when(['trainingService', 'infusionService'], {
    is: (tS: boolean, iS: boolean) => tS || iS,
    then: yup
      .string()
      .required('Campo obbligatorio')
      .oneOf(visitDateChangeAlertOptionValues, 'Inserire il valore corretto'),
    otherwise: yup.string().notRequired(),
  }),
  visitDateChangeAlertType: yup.string().when(['trainingService', 'infusionService'], {
    is: (tS: boolean, iS: boolean) => tS || iS,
    then: yup
      .string()
      .required('Campo obbligatorio')
      .oneOf(visitDateChangeAlertTypeValues, 'Inserire il valore corretto'),
    otherwise: yup.string().notRequired(),
  }),
  visitDateChangeAlertDaysBefore: yup
    .string()
    .when(['trainingService', 'infusionService', 'visitDateChangeAlertOption'], {
      is: (tS: boolean, iS: boolean, vDCAO: string) =>
        (tS || iS) && vDCAO === 'Essere avvisato se si esce da un range temporale (specificare sotto)',
      then: yup.string().required('Campo obbligatorio'),
      otherwise: yup.string().notRequired(),
    }),
  visitDateChangeAlertDaysAfter: yup
    .string()
    .when(['trainingService', 'infusionService', 'visitDateChangeAlertOption'], {
      is: (tS: boolean, iS: boolean, vDCAO: string) =>
        (tS || iS) && vDCAO === 'Essere avvisato se si esce da un range temporale (specificare sotto)',
      then: yup.string().required('Campo obbligatorio'),
      otherwise: yup.string().notRequired(),
    }),

  phoneCallAfterFirstVisit: yup.boolean(),

  // Drug delivery service
  drugDeliveryService: yup.boolean(),
  pharmacyOptions: yup.string().when('drugDeliveryService', {
    is: true,
    then: yup.string().required('Campo obbligatorio').oneOf(pharmacyValues, 'Inserire il valore corretto'),
    otherwise: yup.string().notRequired(),
  }),
  pharmacyInformations: yup.string().when('pharmacyOptions', {
    is: 'Gli estremi della Farmacia sono i seguenti',
    then: yup.string().required('Campo obbligatorio'),
    otherwise: yup.string().notRequired(),
  }),

  requestFollowupOnPatientEnrollment: yup.boolean(),

  physicianConfirmation: yup
    .boolean()
    .oneOf([true], "Senza conferma non è possibile procedere con l'invio della richiesta di attivazione dei servizi"),

  patientLocality: yup.string().when(['trainingService', 'infusionService'], {
    is: (tS: boolean, iS: boolean) => tS || iS,
    then: yup.string().required('Campo obbligatorio'),
    otherwise: yup.string().notRequired(),
  }),
})

const initialValues: FormValues = {
  // Training Service
  trainingService: false,
  patientLocality: '',

  firstTrainingsAtCenter: false,
  numberOfTrainingsAtCenter: '',
  patientAutonomyExpectation: '',
  trainingAutonomyNote: '',

  // InfusionService
  infusionService: false,

  // Therapeutic Plan
  diagnosys: '',
  immunodeficiencyType: '',
  drugName: '',
  pumpModel: '',
  pumpModelOther: '',
  rampupFirstDose: '',
  rampupFirstTiming: '',
  rampupSecondDose: '',
  rampupSecondTiming: '',
  rampupThirdDose: '',
  rampupThirdTiming: '',
  rampupOtherIndications: '',

  maintenanceDose: '',
  maintenanceFrequency: '',
  maintenanceVelocity: '',

  sanitaryInformations: '',
  sanitaryInformationsPreTherapy: false,

  visitDateChangeAlertOption: '',
  visitDateChangeAlertType: '',
  visitDateChangeAlertDaysBefore: '',
  visitDateChangeAlertDaysAfter: '',

  phoneCallAfterFirstVisit: false,

  // Drug delivery service
  drugDeliveryService: false,
  pharmacyOptions: '',
  pharmacyInformations: '',

  // Follow-up on Patient erollment
  requestFollowupOnPatientEnrollment: false,

  // Physician confirmation
  physicianConfirmation: false,
}

export default PspImmunoForm
