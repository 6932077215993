import styled from '@emotion/styled'
import { FormikErrors, FormikTouched } from 'formik'
import mediaQueries from '../../styles/media-queries'

export const Container = styled.div({
  maxWidth: 960,
  margin: '0 auto',
  padding: '40px 16px',
  [mediaQueries('md')]: {
    padding: '80px 32px 40px',
  },
})

export const FormSection = styled.div({
  marginTop: 64,
})
interface FormErrorsProps {
  formik: { submitCount: number; isValid: boolean; status?: { error: string } }
}
export const FormErrors: React.FC<FormErrorsProps> = ({ formik }) => {
  let errors: string[] = []

  if (formik.submitCount !== 0 && !formik.isValid) {
    errors.push(`
            Attenzione, alcuni campi non sono stati compilati correttamente. E' necessario controllare e correggere gli
            errori per sottomettere nuovamente l'adesione
          `)
  }

  if (formik.status?.error) {
    errors.push(formik.status.error)
  }

  return (
    <div>
      {errors.map((e, i) => (
        <div
          key={i}
          css={{ color: '#fb4600', textAlign: 'center', fontSize: 18, marginTop: errors.length > 1 ? 32 : undefined }}
        >
          {e}
        </div>
      ))}
    </div>
  )
}

export function genOptions<T>(values: Array<T>) {
  return values.map((t) => ({ label: t, value: t }))
}

export const fieldErrors = <T,>(fieldName: keyof T, formik: { touched: FormikTouched<T>; errors: FormikErrors<T> }) => {
  return formik.touched[fieldName] ? formik.errors[fieldName] : ''
}
