import React from 'react'
import Header from './header'
import PrivacyDisclaimer from './privacy-disclaimer'
import Footer from './footer'

interface Props {}

const PrivacyPage: React.FC<Props> = () => (
  <div>
    <Header title="Informativa Privacy Medico" />
    <div css={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <div
        css={{
          maxWidth: 960,
          padding: 16,
          margin: '16px auto',
        }}
      >
        <PrivacyDisclaimer baseFontSize={16} />
      </div>
    </div>
    <Footer />
  </div>
)

export default PrivacyPage
